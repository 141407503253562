"use client"

import { ColumnDef } from "@tanstack/react-table"

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
import { ChevronRightIcon,ChevronLeftIcon,DownloadIcon } from "@radix-ui/react-icons"
import { TiTick } from "react-icons/ti";

import { Button } from "../../../../shadcn/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../shadcn/components/ui/dropdown-menu"


import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../shadcn/components/ui/tooltip"

import { ColorMap,statusMap } from "../../../../lib/utils";
import { Chip } from "@nextui-org/react";

function Capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}

export const columns= [
  // {
  //   accessorKey: "imgUrl",
  //   header: "Preview",
  //   cell: ({ row }) => {
  //     return (
  //       <img width={75} height={75} style={{maxHeight: 75, maxWidth: 75, objectFit: 'contain'}} src={row.original['imgUrl']} />
  //     )
  //   },
  // },
{
    header: "S No",
        cell: ({ row }) => {
      return (
        <div>{row.index + 1}</div>
      )
    },
},

{
  header: "Report Type",
  cell: ({ row }) => {
    return (
      <div>
      <Chip className={(row.original['custom_report_type'] == 3 )?'bg-purple-500 text-white':'bg-pink-500 text-white'}>{(row.original['custom_report_type'] == 3 )?'AUTO':'MANUAL'}</Chip>
      </div>
    )
  },
},

  {
    accessorKey: "report_id",
    header: "Report ID",
  },
  {
    accessorKey: "parcel_id",
    header: "Parcel ID",
  },
  {
    accessorKey: "full_address",
    header: "Address",
  },
  // {
  //   header: "Summary",
  //   cell: ({ row }) => {
  //     return (
  //       <>{Capitalize(row.original['house_type'])} with {row.original['no_of_floors']} floors</>
  //     )
  //   },
  // },
  {
    accessorKey: "quote_status",
    header: "Quote Status",
        cell: ({ row }) => (
      <div><Chip color={ColorMap[row.original['quote_status']]}>{statusMap[row.original['quote_status']]}</Chip></div>
    ),
  },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   cell: ({row}) => {
  //     return <div style={{textAlign: 'center', display: 'inline-block'}}>
  //       {row.original['status']==='PDF_GENERATED'?(<TiTick className="h-8 w-8" style={{color: 'green'}} />):''}
  //     </div>
  //   }
  // },
  // {
  //   accessorKey: "action",
  //   header: "Action",
    
  //   cell: ({ row }) => {
  //     return <div className="text-center font-medium"><DownloadIcon/></div>
  //   },
  // },
  // {
  //   id: "actions",
  //   cell: ({ row }) => {
  //     // const payment = row.original
  //     // console.log(row.original)
  //     return (
  //       <Button variant="ghost" className="h-8 w-8 p-0  hover:!text-black hover:!bg-[#00ff99]">
  //       <DownloadIcon className="h-4 w-4" />
  //     </Button>
  //     )
  //   },
  // },
]



// export const columns2 = [
//   {
//     id: "select",
//     header: ({ table }) => (
//       <Checkbox
//         checked={
//           table.getIsAllPageRowsSelected() ||
//           (table.getIsSomePageRowsSelected() && "indeterminate")
//         }
//         onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
//         aria-label="Select all"
//       />
//     ),
//     cell: ({ row }) => (
//       <Checkbox
//         checked={row.getIsSelected()}
//         onCheckedChange={(value) => row.toggleSelected(!!value)}
//         aria-label="Select row"
//       />
//     ),
//     enableSorting: false,
//     enableHiding: false,
//   },
//   {
//     accessorKey: "status",
//     header: "Status",
//     cell: ({ row }) => (
//       <div className="capitalize">{row.getValue("status")}</div>
//     ),
//   },
//   {
//     accessorKey: "email",
//     header: ({ column }) => {
//       return (
//         <Button
//           variant="ghost"
//           onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
//         >
//           Email
//           <CaretSortIcon className="ml-2 h-4 w-4" />
//         </Button>
//       )
//     },
//     cell: ({ row }) => <div className="lowercase">{row.getValue("email")}</div>,
//   },
//   {
//     accessorKey: "amount",
//     header: () => <div className="text-right">Amount</div>,
//     cell: ({ row }) => {
//       const amount = parseFloat(row.getValue("amount"))
 
//       // Format the amount as a dollar amount
//       const formatted = new Intl.NumberFormat("en-US", {
//         style: "currency",
//         currency: "USD",
//       }).format(amount)
 
//       return <div className="text-right font-medium">{formatted}</div>
//     },
//   },
//   {
//     id: "actions",
//     enableHiding: false,
//     cell: ({ row }) => {
//       const payment = row.original
 
//       return (
//         <DropdownMenu>
//           <DropdownMenuTrigger asChild>
//             <Button variant="ghost" className="h-8 w-8 p-0">
//               <span className="sr-only">Open menu</span>
//               <DotsHorizontalIcon className="h-4 w-4" />
//             </Button>
//           </DropdownMenuTrigger>
//           <DropdownMenuContent align="end">
//             <DropdownMenuLabel>Actions</DropdownMenuLabel>
//             <DropdownMenuItem
//               onClick={() => navigator.clipboard.writeText(payment.id)}
//             >
//               Copy payment ID
//             </DropdownMenuItem>
//             <DropdownMenuSeparator />
//             <DropdownMenuItem>View customer</DropdownMenuItem>
//             <DropdownMenuItem>View payment details</DropdownMenuItem>
//           </DropdownMenuContent>
//         </DropdownMenu>
//       )
//     },
//   },
// ]


