import React from "react";
import { useEffect, useRef,useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../utils/helpers";
import SuccessImg from '../images/ic_success.png';
import FailureImg from '../images/ic_failure.png';
import LoadingImg from '../images/loading.gif';
import { toggleLoader } from "../actions";
import { useDispatch } from "react-redux";
import { Urls } from "../utils/AppConfig";
import Report from '../components/Report/Report'
import HomeHeader from "../components/HomeHeader";
import Quotation from "../components/PlotWorks/Quotation";

const QuotationPage = (props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
  const [reportData, setReportData] = useState(null);

    let reportTypeId = searchParams.get("quoteTypeId");
    let reportType = searchParams.get("quoteType");

    let token = window.localStorage.getItem('token');

  useEffect(() => {
    loadData()
  }, []);

  const loadData = () => {
    fetchPost(Urls.GetReportTypeDetails, { reportType,reportTypeId }, token)
      .then(r => {
        console.log(r);
        if (r.error) {
          return window.alert("Server error");
        }
        setReportData(r.data);
      })
      .catch(err => {
        console.log(err);
      })
  }

  if (!reportData) {
    return '...';
  }
  

    return <>
    <HomeHeader />
        <div className="items-center flex justify-center bg-transparent text-white text-center">

<div className="  lg:w-10/12 md:w-11/12 w-full ">

<Quotation props={reportData} />


</div>

</div>
    </>
}

export default QuotationPage;