import React, { useState,useEffect } from 'react';
import PlottieLogo from "../../images/logo/PlottieLogo_light.png";
import { AnimatedTestimonials } from "../../components/ui/animated-testimonials";
import {ExpandableCard} from "../../components/ui/ExpandableCard";
import img1 from '../../images/pdf/Picture3.png';

import {engineeringData,architectureData,planningData,costingData} from '../../data/PlottieUniversity';

export const ProductGuide = () => {

    const [definitions,setDefinitions] = useState(engineeringData);
    const [viewDefs,setViewDefs] = useState(false);
    const [active, setActive] = useState(0);

    

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDataFromChild = (data) => {
if(data == 1){
  setDefinitions(engineeringData);

}else if(data == 2){
  setDefinitions(architectureData);

}else if(data == 3){
  setDefinitions(planningData);

}else if(data == 4){
  setDefinitions(costingData);
}
setViewDefs(true);
setActive(0);

  };
  // getDefinitions = (newMessage) => {
  //     alert(newMessage);
  // }

  return (
    <>
        <div className='pb-10 pt-[100px] bg-black text-justify scroll-mt-[72px]'  style={{ fontFamily: 'Verdana, sans-serif' }}>
        
        {/* <div className='flex items-center justify-center p-6'>
          <img src={PlotieeLogo} width={100} height={50} />
        </div> */}
        <div className='text-[#00ff99] flex flex-col mt-5 font-extrabold text-center '>
            <div className='text-4xl flex flex-row justify-center'>
              
              PLOTTIE UNIVERSITY
            
            </div>
            <div className='text-2xl items-center justify-center flex flex-row'><span>Don't Be a Plot-tato</span><span>
              {/* <CursorArrowIcon height={20} width={20} /> */}
              {/* <img src={PlotieeLogo} width={30} height={20} /> */}
              </span> </div>
        </div>
        </div>

        <div className='text-[#00ff99] text-lg justify-center text-justify items-center space-y-8 lg:space-y-0 flex flex-col w-full lg:flex-row lg:w-11/12'>

<div className='space-y-8 w-11/12 lg:w-1/2'>
<div className=''>
     Imagine standing on a piece of land filled with possibilities but uncertain
 where to start. Whether you dream of building your own haven, adding a
 cozy minor dwelling, or creating a space that blends function and
 aesthetics, navigating the journey from idea to reality can be
 overwhelming. That's where we come in.</div>

 <div className='flex flex-row gap-4'>

        <div className=''>
        <img style={{float:'left',paddingRight:10,paddingBottom:0}} src={PlottieLogo} width={60} height={20}/>
Welcome to Plottie University, your trusted guide through every step of
 property development. From the technicalities of engineering to the
 artistry of architecture, the practicalities of planning, and the essentials
 of costing, we're here to demystify the process and turn complex terms
 into clear directions. Consider this your compass, helping you navigate
 the nuances of property potential and making informed, confident
 decisions for a truly successful project.
 </div>
 </div>
 <div className='flex items-center justify-center lg:hidden'>
  <img src = {img1}
   width={400}
   height={500}
  />
 </div>
 <div className=''>
 Let’s dive into the essentials, starting with the four core pillars that shape
 your project: <span className='text-white text-2xl'>Engineering, Architecture, Planning, Costing.</span>
 </div>
 </div>
 <div className='hidden lg:flex'>
  <img src = {img1}
   width={400}
   height={500}
  />
 </div>

        </div>

        <div className='flex justify-center items-center '>
    <hr className='w-4/5 bg-[#00ff99] h-1'/>
    </div>
        <ExpandableCard  sendDataToParent={handleDataFromChild} />



<AnimatedTestimonials testimonials={definitions} viewDefinition={viewDefs} buttonStatus={active}/>


    </>

    )

    }