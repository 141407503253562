import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import {ProductGuide,AnimatedTestimonialsDemo} from "../components/Landing/ProductGuide";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const ProductGuidePage = () => {
    const dispatch = useDispatch();



    return <>
        <Cursor/>
        <ScrollToAnchor />
        <HeaderSection/>
        <ProductGuide />
        {/* <AnimatedTestimonialsDemo/> */}

<FooterSection />
    </>
}

export default ProductGuidePage;