
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import { useEffect,useState } from 'react';
import { Input,Textarea, Select, SelectItem,Checkbox,Switch} from "@nextui-org/react";

import OkahuBay from "../images/OkahuBay.jpg";
import PPLogo from "../images/PPLogo.png";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PopUp } from "./Popup";
import React from 'react';
import confetti from 'canvas-confetti';
import { useDispatch } from 'react-redux';
import { fetchPost } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";



export const LaunchPopup = ({launch}) => {  
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [data, setData] = useState([]);


  const type = [
    {label: "Engineer", value: 1},
    {label: "Architect", value: 2},
    {label: "Planner", value: 3},
    {label: "Building Inspector", value: 4},
    {label: "Contractor", value: 5},
    ];

    const [stepOne, setStepOne] = useState(false);
    const [stepTwo, setStepTwo] = useState(false);
    const [stepThree, setStepThree] = useState(false);

    const formik =  useFormik({
    
        enableReinitialize:true,
        initialValues: {
            name:"",
            email:"",
            phone:"",
            type:"",

        },
        validationSchema: Yup.object({
          name:Yup.string().required('Your Name is Required !'),
          email:Yup.string().required('E-mail is Required !'),
          phone:Yup.string().required('Phone Number is Required !'),
          type:Yup.string().required('Type is Required !'),
        }),
          onSubmit: async(values) => {
    openStepThree();
    },
    
      });


useEffect(()=>{


  console.log(launch);

        if(launch == true){
            setStepOne(true);
            setStepTwo(false);
            setStepThree(false);
            onOpen();

        }
  }, [launch]);


  const openStepTwo = () =>{
    setStepOne(false);
    setStepTwo(true);
    setStepThree(false);
  }

  const openStepThree = () =>{

    setStepOne(false);
    setStepTwo(false);

    fetchPost(Urls.RegisterLaunch)
    .then(response => {
      console.log(response);
      let data = {};
      if (response.error) {
         data = {
          status:false,
          msg:'Something Went Wrong'
      };
      }else{
        data = {
          status:true,
          msg:response.message,
          data:response.data
      };
      setStepThree(true);
      handleConfetti();
      }
    setData(data);
    });




  }



  var duration = 15 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 50 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const handleConfetti = () => {
    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
        })
      );
    }, 250);
  };


  return (
    <>
              
    <div>
    {data.status ?'':<PopUp props={data}/>}

    {stepOne ? 
      <Modal
        className="z-50"
      placement="center"
      size="3xl"
      backdrop="blur"
      classNames={{closeButton:"z-50  hover:bg-[#00ff99]"}}
      isOpen={isOpen} onOpenChange={onOpenChange} hideCloseButton={true} isDismissable={false} isKeyboardDismissDisabled={true}>
        <ModalContent
        >
      
          {(onClose) => (
            <>      
              <div className="relative " style={{fontFamily: 'Verdana, sans-serif' }}>
              <img src={OkahuBay} alt="OkahuBay" className="-z-30 w-full h-full inset-0 opacity-70 absolute object-cover object-right" />
            <ModalHeader></ModalHeader>
              <ModalBody>
              <div className="md:px-20 pt-10">
        <div className="border-2  flex flex-col p-4 items-center justify-center border-[#00ff99] rounded-xl">
            <div className="text-white head_font2 text-6xl font-extrabold">
                Register 
            </div>
            <div className="text-white head_font2 text-6xl font-extrabold">
                 Now
            </div>
            <div className="text-white head_font2 text-lg pt-3 text-center">
                Book your Seat for Plot Potential's launch
            </div>
    
            <button className="pt-3 text-white" onClick={openStepThree}>
    <div className="inline-flex items-center hover:bg-[#00ff99] hover:text-black font-extrabold   head_font2 rounded-3xl px-4 py-2  border-2 border-[#00ff99]  cursor-pointer  ">
    Generate Ticket for Launch party</div>
    </button> 
    <div className=" head_font2  pt-3 montserrat ">
                <span className="text-9xl font-black bg-gradient-to-r from-[#adffb9] to-[#39a553] inline-block text-transparent bg-clip-text">12</span>
            </div>
            <div className="text-white head_font2 text-lg  ">
                FEBRUARY
            </div>
            <div className="text-white head_font2 text-lg pt-3 text-center">
                <div>
                Tuesday, 12 Feb 2025
                </div>
                <div>
                7 PM - 11 PM
                </div>
            </div>
        </div>
    </div>

{/* 
{stepTwo ? 
              <div className="md:px-20 pt-10">
        <div className="border-2  flex flex-col p-4 items-center justify-center border-[#00ff99] rounded-xl">
            <div className="text-white head_font2 text-6xl font-extrabold">
                Register 
            </div>
            <div className="text-white head_font2 text-6xl font-extrabold">
                 Now
            </div>
            <p className="text-center text-lg pt-2 text-white">Complete the Registration and we’ll get back to you soon.</p>
    
            <div className="pt-1">
            <form  encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className=" w-full mx-auto">
            <div className="flex flex-wrap ">
    
            <div className="p-2 w-full">
            <Input 
            className="text-white" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            value={formik.values.name}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ]}}
                variant="bordered"
            type="text" radius="full" placeholder="Name" isRequired/>
            </div>
    
            <div className="p-2 w-full">
            <Input
            className="text-white" 
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ]}}
                variant="bordered"
             type="email" radius="full"  placeholder="Email" isRequired/>
              </div>
    
              <div className="p-2 w-full">
              <Input 
            className="text-white" 
                name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ],             
            }
            }
                variant="bordered" 
            type="text" radius="full"  placeholder="Phone" isRequired/>
            </div>    
    
            <div className="p-2 w-full">
            <Select
            className="text-[#00ff99] w-full" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            name="type"
            radius="full"
            placeholder="Select"
            classNames={{trigger:["border-[#00ff99]",
                "bg-[#333]",
                "text-[#00ff99]",
                "data-[hover=true]:border-[#00ff99]"],
                popoverContent:["bg-[#333]","text-[#00ff99]"],
                }}
                variant="bordered"
            >
            {type.map((type) => (
                <SelectItem key={type.label}  value={type.label}>
                {type.label}
                </SelectItem>
            ))}
            </Select>
            </div>   
    
            </div>                   
            </div>
    
    
    <div className="items-center flex justify-center">
    <button className="pt-3 text-white " type="submit">
    <div className="inline-flex  hover:bg-[#00ff99] hover:text-black font-extrabold   head_font2 rounded-3xl px-4 py-2  border-2 border-[#00ff99]  ">
    Submit</div>
    </button>      
    </div>
            </form>
            </div>               
        </div>
    </div>
    : ''} */}
 
              </ModalBody>
              <ModalFooter className="flex ">
              {/* <Button  className="bg-[#00ff99] text-black" variant="shadow" onPress={onClose}>
                  Close
             </Button> */}
              </ModalFooter> 
              </div>
            </>
          )}
        </ModalContent>
      </Modal>
      : ''}



{stepThree ? 
      <Modal
        className="z-50"
      placement="center"
      size="3xl"
      backdrop="blur"
      classNames={{closeButton:"z-50  hover:bg-[#00ff99]"}}
      isOpen={isOpen} onOpenChange={onOpenChange}  isDismissable={false} isKeyboardDismissDisabled={true}>
        <ModalContent
        >
      
          {(onClose) => (
            <>      
              <div className="relative " style={{fontFamily: 'Verdana, sans-serif' }}>
              <img src={OkahuBay} alt="OkahuBay" className="-z-30 w-full h-full inset-0 opacity-70 absolute object-cover object-right" />
            <ModalHeader></ModalHeader>
              <ModalBody>

{/* 
{stepTwo ? 
              <div className="md:px-20 pt-10">
        <div className="border-2  flex flex-col p-4 items-center justify-center border-[#00ff99] rounded-xl">
            <div className="text-white head_font2 text-6xl font-extrabold">
                Register 
            </div>
            <div className="text-white head_font2 text-6xl font-extrabold">
                 Now
            </div>
            <p className="text-center text-lg pt-2 text-white">Complete the Registration and we’ll get back to you soon.</p>
    
            <div className="pt-1">
            <form  encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className=" w-full mx-auto">
            <div className="flex flex-wrap ">
    
            <div className="p-2 w-full">
            <Input 
            className="text-white" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            value={formik.values.name}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ]}}
                variant="bordered"
            type="text" radius="full" placeholder="Name" isRequired/>
            </div>
    
            <div className="p-2 w-full">
            <Input
            className="text-white" 
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ]}}
                variant="bordered"
             type="email" radius="full"  placeholder="Email" isRequired/>
              </div>
    
              <div className="p-2 w-full">
              <Input 
            className="text-white" 
                name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ],             
            }
            }
                variant="bordered" 
            type="text" radius="full"  placeholder="Phone" isRequired/>
            </div>    
    
            <div className="p-2 w-full">
            <Select
            className="text-[#00ff99] w-full" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            name="type"
            radius="full"
            placeholder="Select"
            classNames={{trigger:["border-[#00ff99]",
                "bg-[#333]",
                "text-[#00ff99]",
                "data-[hover=true]:border-[#00ff99]"],
                popoverContent:["bg-[#333]","text-[#00ff99]"],
                }}
                variant="bordered"
            >
            {type.map((type) => (
                <SelectItem key={type.label}  value={type.label}>
                {type.label}
                </SelectItem>
            ))}
            </Select>
            </div>   
    
            </div>                   
            </div>
    
    
    <div className="items-center flex justify-center">
    <button className="pt-3 text-white " type="submit">
    <div className="inline-flex  hover:bg-[#00ff99] hover:text-black font-extrabold   head_font2 rounded-3xl px-4 py-2  border-2 border-[#00ff99]  ">
    Submit</div>
    </button>      
    </div>
            </form>
            </div>               
        </div>
    </div>
    : ''} */}


    <div className="md:px-20 pt-10 ">
        <div className="border-2  flex flex-col p-4 items-center justify-center border-[#00ff99] rounded-xl">
            <div className="text-white text-center head_font2 text-4xl md:text-6xl  font-extrabold pb-3">
                Your spot is locked in. 
            </div>
            <div className="w-52 h-52 flex justify-center items-center border-2 border-[#00ff99] rounded-3xl">
                <div className="head_font2 text-4xl text-white font-bold">
                <img src={data.data} className="rounded-3xl"/>
                </div>
            </div>
            <div className="text-white head_font2 text-md md:text-lg pt-3 text-center">
                <div className="font-bold">
                <span className="text-[#00ff99]">You're in! 🎉 </span><span className="">Keep an eye on your inbox for your ticket to the launch event.</span>
                </div>
                <div>
                 <span>We couldn’t be more excited to have you with us for this big moment.</span>
                </div>
                <div>
                 <span className="text-[#00ff99]">Thanks for being a part of our journey</span>
                </div>
            </div>
        </div>
    </div>
              </ModalBody>
              <ModalFooter className="flex ">
              <Button  className="bg-[#00ff99] text-black" variant="shadow" onPress={onClose}>
                  Close
             </Button>
              </ModalFooter> 
              </div>
            </>
          )}
        </ModalContent>
      </Modal>
      : ''}

      </div>
    </>
  );
}



export const DemoPopup = ({demo}) =>{

  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [data, setData] = useState([]);

  useEffect(()=>{

    if(demo.status == true){
      onOpen();
  }else if(demo.status == false){
    setData(demo);
  }

}, [demo]);

return (
  <>
    {data.status ?'':<PopUp props={data}/>}

  <div>
    <Modal
           className="z-50"
           placement="center"
           size="3xl"
           backdrop="blur"
    isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false} isKeyboardDismissDisabled={true}>
      <ModalContent>
        {(onClose) => (
          <>
          <ModalHeader></ModalHeader>
            <ModalBody>

            <div className="md:px-20 pt-10 " style={{fontFamily: 'Verdana, sans-serif' }}>
    <div className="border-2  flex flex-col p-4 items-center justify-center border-[#00ff99] rounded-xl text-center">
        <div className="text-white head_font2 text-2xl  md:text-3xl font-extrabold pb-3">
        Awesome! You’re just one step away from unlocking your 
        potential.
        </div>
        <p className="text-center text-lg pt-2 text-white"> Thanks for booking the demo!</p>            

          <div>
            <svg version="1.1" id="Layer_1" width={90} height={90} viewBox="0 0 512 512" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
               <path fill="#DDEFFB" d="M511.957,252.523C510.092,112.744,396.222,0,256,0C114.616,0,0,114.616,0,256 c0,114.3,74.913,211.09,178.315,243.985L511.957,252.523z"></path>
                <path fill="#9ACEF3" d="M512,256c0-1.162-0.029-2.317-0.045-3.477L318.934,59.501L193.066,185.368l47.201,47.201L95.722,384 l38.957,38.957l-16.696,16.696l60.333,60.333C202.818,507.78,228.915,512,256,512C397.385,512,512,397.385,512,256z"></path> 
               <polygon fill="#4C4C4C" points="416.278,272.696 349.496,272.696 394.017,384 416.278,384 "></polygon> <g>
                 <polygon fill="#666666" points="95.722,272.696 162.504,272.696 117.983,384 95.722,384 "></polygon>
                  <rect x="319.443" y="372.87" fill="#666666" width="22.261" height="55.652"></rect> </g> 
                  <rect x="170.296" y="372.87" fill="#7F7F7F" width="22.261" height="55.652"></rect> <g> 
                    <polygon fill="#666666" points="394.017,417.391 394.017,439.652 256,439.652 244.87,428.522 256,417.391 "></polygon>
                     <polygon fill="#666666" points="394.017,361.739 394.017,384 256,384 244.87,372.87 256,361.739 267.13,361.739 330.574,339.478 "></polygon> 
                     </g> <g> <rect x="117.983" y="417.391" fill="#7F7F7F" width="138.017" height="22.261"></rect>
                      <polygon fill="#7F7F7F" points="256,361.739 256,384 117.983,384 117.983,361.739 181.426,339.478 244.87,361.739 "></polygon>
                       </g> <path fill="#4A75C3" d="M244.87,245.983v82.365l-96.835,11.13l33.391-115.757h41.183 C234.852,223.722,244.87,233.739,244.87,245.983z"></path>
             <path fill="#458FDE" d="M181.426,223.722v115.757l-63.443-11.13v-82.365c0-12.243,10.017-22.261,22.261-22.261H181.426z"></path>
              <polygon fill="#4F5AA8" points="181.426,328.348 156.556,349.546 181.426,361.739 244.87,361.739 244.87,328.348 "></polygon> <g> 
                <rect x="117.983" y="328.348" fill="#4A75C3" width="63.443" height="33.391"></rect>
                 <path fill="#4A75C3" d="M394.017,245.983v82.365l-96.835,11.13l33.391-115.757h41.183 C384,223.722,394.017,233.739,394.017,245.983z"></path> </g> 
                 <path fill="#458FDE" d="M330.574,223.722v115.757l-63.443-11.13v-82.365c0-12.243,10.017-22.261,22.261-22.261H330.574z"></path> 
                 <polygon fill="#4F5AA8" points="330.574,328.348 305.704,349.546 330.574,361.739 394.017,361.739 394.017,328.348 "></polygon> 
                 <rect x="267.13" y="328.348" fill="#4A75C3" width="63.443" height="33.391"></rect>
                  <path fill="#2C4290" d="M256,211.478l-53.426-89.043L256,33.391c49.099,0,89.043,39.945,89.043,89.043 S305.1,211.478,256,211.478z"></path>
                   <path fill="#4F5AA8" d="M166.957,122.435c0,49.1,39.944,89.043,89.043,89.043V33.391 C206.901,33.391,166.957,73.336,166.957,122.435z"></path> 
                   <path fill="#DDEFFB" d="M256,189.217l-40.07-66.783L256,55.652c36.824,0,66.783,29.959,66.783,66.783 S292.825,189.217,256,189.217z"></path>
                    <path fill="#FFFFFF" d="M189.217,122.435c0,36.824,29.959,66.783,66.783,66.783V55.652 C219.176,55.652,189.217,85.611,189.217,122.435z"></path>
             <polygon fill="#9ACEF3" points="222.609,133.565 267.13,133.565 267.13,77.913 244.87,77.913 244.87,111.304 222.609,111.304 "></polygon> </g></svg>
          </div>

        <p className="text-center text-lg pt-2 text-white"> One of our team members will be in touch shortly.</p>            

    </div>
</div>



            </ModalBody>
            <ModalFooter className="flex ">
          <Button  className="bg-[#00ff99] text-black" variant="shadow" onPress={onClose}>
              Close
         </Button>
          </ModalFooter> 
          </>
        )}
      </ModalContent>
    </Modal>
    </div>
  </>
);

}




export const DemoPopup1 = ({demo}) => {  
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

    const type = [
        {label: "Engineer", value: 1},
        {label: "Architect", value: 2},
        {label: "Planner", value: 3},
        {label: "Building Inspector", value: 4},
        {label: "Contractor", value: 5},
        ];
    
        const formik =  useFormik({
        
            enableReinitialize:true,
            initialValues: {
                name:"",
                email:"",
                phone:"",
                type:"",
    
            },
            validationSchema: Yup.object({
              name:Yup.string().required('Your Name is Required !'),
              email:Yup.string().required('E-mail is Required !'),
              phone:Yup.string().required('Phone Number is Required !'),
              type:Yup.string().required('Type is Required !'),
            }),
              onSubmit: async(values) => {
        const data = {
            status:true,
            msg:'Return Msg'
        };
        setData(data);
        setStatus(true);

        if(data.status == true){
            onOpenChange();
        }

        },
        
          });
  
    useEffect(()=>{
        if(demo == true){
            onOpen();
        }
  }, [demo]);
  
    return (
      <>
      <div>
        {status ? <PopUp props={data}/>:''}
        {/* <Button onPress={onOpen}>Open Modal</Button> */}
        <Modal
               className="z-50"
               placement="center"
               size="3xl"
               backdrop="blur"
        isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false} isKeyboardDismissDisabled={true}>
          <ModalContent>
            {(onClose) => (
              <>
              <ModalHeader></ModalHeader>
                <ModalBody>

                <div className="md:px-20 pt-10 " style={{fontFamily: 'Verdana, sans-serif' }}>
        <div className="border-2  flex flex-col p-4 items-center justify-center border-[#00ff99] rounded-xl text-center">
            <div className="text-white head_font2 text-3xl  md:text-5xl font-extrabold">
            Book for an Exclusive Demo
            </div>
            <p className="text-center text-lg pt-2 text-white">Complete the form and we’ll get back to you.</p>
    
            <div className="pt-1">
            <form  encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className=" w-full mx-auto">
            <div className="flex flex-wrap ">
    
            <div className="p-2 w-full">
            <Input 
            className="text-white" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            value={formik.values.name}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ]}}
                variant="bordered"
            type="text" radius="full" placeholder="Name" isRequired/>
            </div>
    
            <div className="p-2 w-full">
            <Input
            className="text-white" 
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ]}}
                variant="bordered"
             type="email" radius="full"  placeholder="Email" isRequired/>
              </div>
    
              <div className="p-2 w-full">
              <Input 
            className="text-white" 
                name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            classNames={{inputWrapper:[
                "border-[#00ff99]",
                "bg-[#333]",
                "data-[hover=true]:border-[#00ff99]",
                ],             
            }
            }
                variant="bordered" 
            type="text" radius="full"  placeholder="Phone" isRequired/>
            </div>    
    
            <div className="p-2 w-full">
            <Select
            className="text-[#00ff99] w-full" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            name="type"
            radius="full"
            placeholder="Select"
            classNames={{trigger:["border-[#00ff99]",
                "bg-[#333]",
                "text-[#00ff99]",
                "data-[hover=true]:border-[#00ff99]"],
                popoverContent:["bg-[#333]","text-[#00ff99]"],
                }}
                variant="bordered"
            >
            {type.map((type) => (
                <SelectItem key={type.label}  value={type.label}>
                {type.label}
                </SelectItem>
            ))}
            </Select>
            </div>   
    
            </div>                   
            </div>
    
    
    <div className="items-center flex justify-center">
    <button className="pt-3 text-white " type="submit">
    <div className="inline-flex  hover:bg-[#00ff99] hover:text-black font-extrabold   head_font2 rounded-3xl px-4 py-2  border-2 border-[#00ff99]  ">
    Book Now</div>
    </button>      
    </div>
            </form>
            </div>               
        </div>
    </div>



                </ModalBody>
                <ModalFooter className="flex ">
              <Button  className="bg-[#00ff99] text-black" variant="shadow" onPress={onClose}>
                  Close
             </Button>
              </ModalFooter> 
              </>
            )}
          </ModalContent>
        </Modal>
        </div>
      </>
    );
  }



  
 
  
