import * as React from 'react';
import { connect } from 'react-redux';
import MainLandingSection from '../components/Landing/MainSection';
import AppHeader from '../components/AppHeader.js';
import ServiceSection from '../components/Landing/ServiceSection';
import AboutSection from '../components/Landing/AboutSection';
import ContactSection from '../components/Landing/ContactSection';
import PaneSection from '../components/Landing/PaneSection';
import FooterSection from '../components/Landing/FooterSection';
import {CRMSection,AboutHomeSection,ReportSection,TreeSection,PartnerHomeSection,ServiceHomeSection,FeatureSection} from '../components/Landing/HomeSection';
import HeaderSection from '../components/Landing/HeaderSection';
import SubscriptionPlan from '../components/Profile/subscription/SubscriptionPlan';
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';
import { useEffect,useState } from 'react';
import { LaunchPopup,DemoPopup } from '../components/LandingPopup';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import {motion} from 'framer-motion';
import {  Urls } from '../utils/AppConfig';
import { setUser, toggleLoader } from '../actions';
import { useDispatch } from 'react-redux';
import { fetchPost, validateToken } from '../utils/helpers';

import LogoImg from '../images/logo.png';


import { LaunchCard,DemoCard } from '../components/Landing/PopUpSection';

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer,
        map: state.MapReducer
    }
}


function LandingPage(props) {

    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const dispatch = useDispatch();



    const [demo, setDemo] = useState(false);
    const [launch, setLaunch] = useState(false);
    const [data, setData] = useState([]);



      const openLaunchPopup = () =>{
        onOpenChange();
        setLaunch(true);
      }

      const openDemoPopup = () =>{
        fetchPost(Urls.RegisterDemo)
        .then(response => {
          let data = {};
          if (response.error) {
             data = {
              status:false,
              msg:'Something Went Wrong'
          };
          }else{
            data = {
              status:true,
              msg:response.message
          };
          }
          onOpenChange();
          setData(data);
          setDemo(true);
        });
      }

      //backend data
      const [demoRegistered, setDemoRegistered] = useState(false); 
      const [launchRegistered, setLaunchRegistered] = useState(false); 
      //backend data


    useEffect(()=>{
      if(!props.data.user){
        setDemoRegistered(true);
        setLaunchRegistered(true);
        return;
      }
          if(props.data.user && props.data.user.demo_registered_on != null){
            setDemoRegistered(true);
          }
          if(props.data.user && props.data.user.launch_event_registered_on != null){
            setLaunchRegistered(true);
          }

        onOpen();
    },[props])

    

    return <>
    <Cursor/>
    <ScrollToAnchor />

        {launch ? <LaunchPopup launch={launch}/> : ''}
        {demo ? <DemoPopup demo={data}/> : ''}

        
        {/* props.data.user.email.indexOf("plotpotential.co.nz")>-1 */}
         {true?(<>
          <HeaderSection />
        <div style={{ height: 70, width: '100%', background: 'black' }}></div>
        <MainLandingSection />
        <FeatureSection/>
        <ReportSection/>
        <CRMSection/>
        <AboutHomeSection/>
        <ServiceHomeSection />
        <SubscriptionPlan />
        <FooterSection />
         </>):(<div className='text-[#00ff99] flex flex-col justify-center items-center h-screen space-y-10'>
          <img src={LogoImg} width={300} height={150}/>

         <h1 className="head_font sm:text-4xl md:text-5xl lg:text-6xl"><strong >Coming Soon</strong></h1>
        </div> )}

        {/* <AppHeader /> */}
        {/* <PartnerHomeSection/> */}
        {/* <TreeSection/> */}
        {/* <PaneSection /> */}
        {/* <ServiceSection /> */}
        {/* <AboutSection /> */}
        {/* <ContactSection /> */}


        {/* <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{ width: '100%' }}>
                <Toolbar style={{ backgroundColor: 'black' }} />
                <LandingPageContent scrollTo={scrollTo} />
            </Box>
        </Box> */}


{(launchRegistered == true && demoRegistered == true) ? '': 

      <Modal 
      className='  overflow-auto '
        size='4xl'

        hideCloseButton={(launchRegistered == true || demoRegistered == true) ? false: true}

      isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false} isKeyboardDismissDisabled={true}
      placement='center'
      backdrop='blur'
      style={{ fontFamily: 'Verdana, sans-serif' }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 items-center justify-center text-white head_font">Latest Updates</ModalHeader>
              <ModalBody className='text-white'>
            <div className='flex flex-col items-center justify-center gap-4 lg:flex-row'>
          {demoRegistered ?'':<DemoCard openDemoPopup={openDemoPopup}/>}
          {launchRegistered ?'':<LaunchCard openLaunchPopup={openLaunchPopup}/>}
            </div>
              </ModalBody>
              <ModalFooter>
              {(launchRegistered == true || demoRegistered == true) ?  
              <Button  variant="shadow" className='bg-[#00ff99] text-black' onPress={onClose}>
                  Close
                </Button> : ''
                }
              
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    }

    </>;
}
export default connect(mapStateToProps)(LandingPage);