import * as React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Theme } from '../../utils/AppConfig';

const TitleValuation = (props) => {

    let rateAssessment = props.data;

    return !Boolean(rateAssessment) ? '' : (
        <div style={
            {
                margin: 8,
                padding: 8
            }
        }>
            <p className='header-1' style={{ color: Theme.PrimaryLogo }}>
                Council valuation (2021)
            </p>

            <Row>
                <Col item xs={4}>
                    <p className={'caption'} style={{ color: '#ddd' }}>Capital value</p>
                    <p className='content-text-details-2'>$ {(rateAssessment['lcv'] / 1000000).toFixed(2)}M</p>
                </Col>
                <Col item xs={4}>
                    <p className={'caption'} style={{ color: '#ddd' }}>Land</p>
                    <p className='content-text-details-2'>$ {(rateAssessment['llv'] / 1000000).toFixed(2)}M</p>
                </Col>
                <Col item xs={4}>
                    <p className={'caption'} style={{ color: '#ddd' }}>Improvement</p>
                    <p className='content-text-details-2'>$ {(rateAssessment['liv'] / 1000).toFixed(2)}K</p>
                </Col>
            </Row>

        </div>
    )
}

export default TitleValuation;