import { ComingSoon } from "../components/ComingSoon";
import HomeHeader from "../components/HomeHeader";
import ApplicationTable from "../components/Profile/plotworks/applications/page";

const ApplicationsPage = (props) => {
    return <>
        <HomeHeader />
        <div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <h3 className="text-[#00ff99] p-3">PlotWorks Applications</h3>
    {/* <ApplicationTable/> */}
    <ComingSoon />
</div>
</div>
    </>
}

export default ApplicationsPage;