

import React from "react";

export const CrossIcon = ({width,height}) => {
  return (

<svg viewBox="0 0 60 60" width={width} height={height} fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><defs><style>  </style></defs><path fill="#e30d0d" fillRule="evenodd" className="cls-1" d="M940,510a30,30,0,1,1,30-30A30,30,0,0,1,940,510Zm15-20.047A3.408,3.408,0,0,1,955,494.77l-0.221.22a3.42,3.42,0,0,1-4.833,0l-8.764-8.755a1.71,1.71,0,0,0-2.417,0l-8.741,8.747a3.419,3.419,0,0,1-4.836,0l-0.194-.193a3.408,3.408,0,0,1,.017-4.842l8.834-8.735a1.7,1.7,0,0,0,0-2.43l-8.831-8.725a3.409,3.409,0,0,1-.018-4.844l0.193-.193a3.413,3.413,0,0,1,2.418-1c0.944,0,3.255,1.835,3.872,2.455l7.286,7.287a1.708,1.708,0,0,0,2.417,0l8.764-8.748a3.419,3.419,0,0,1,4.832,0L955,465.243a3.408,3.408,0,0,1,0,4.818l-8.727,8.737a1.7,1.7,0,0,0,0,2.407Z" id="uncheck" transform="translate(-910 -450)"></path></g></svg>

);
};

export const TickIcon = ({width,height}) => {
    return (
  
<svg viewBox="0 0 60 60" width={width} height={height} fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><defs><style> </style></defs><path className="cls-1" fill="#00ff99" fillRule="evenodd" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" id="check" transform="translate(-770 -450)"></path></g></svg>  
  );
  };

  export const EyeSlashFilledIcon = ({width,height}) => (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height={height}
      role="presentation"
      viewBox="0 0 24 24"
      width={width}
    >
      <path
        d="M21.2714 9.17834C20.9814 8.71834 20.6714 8.28834 20.3514 7.88834C19.9814 7.41834 19.2814 7.37834 18.8614 7.79834L15.8614 10.7983C16.0814 11.4583 16.1214 12.2183 15.9214 13.0083C15.5714 14.4183 14.4314 15.5583 13.0214 15.9083C12.2314 16.1083 11.4714 16.0683 10.8114 15.8483C10.8114 15.8483 9.38141 17.2783 8.35141 18.3083C7.85141 18.8083 8.01141 19.6883 8.68141 19.9483C9.75141 20.3583 10.8614 20.5683 12.0014 20.5683C13.7814 20.5683 15.5114 20.0483 17.0914 19.0783C18.7014 18.0783 20.1514 16.6083 21.3214 14.7383C22.2714 13.2283 22.2214 10.6883 21.2714 9.17834Z"
        fill="currentColor"
      />
      <path
        d="M14.0206 9.98062L9.98062 14.0206C9.47062 13.5006 9.14062 12.7806 9.14062 12.0006C9.14062 10.4306 10.4206 9.14062 12.0006 9.14062C12.7806 9.14062 13.5006 9.47062 14.0206 9.98062Z"
        fill="currentColor"
      />
      <path
        d="M18.25 5.74969L14.86 9.13969C14.13 8.39969 13.12 7.95969 12 7.95969C9.76 7.95969 7.96 9.76969 7.96 11.9997C7.96 13.1197 8.41 14.1297 9.14 14.8597L5.76 18.2497H5.75C4.64 17.3497 3.62 16.1997 2.75 14.8397C1.75 13.2697 1.75 10.7197 2.75 9.14969C3.91 7.32969 5.33 5.89969 6.91 4.91969C8.49 3.95969 10.22 3.42969 12 3.42969C14.23 3.42969 16.39 4.24969 18.25 5.74969Z"
        fill="currentColor"
      />
      <path
        d="M14.8581 11.9981C14.8581 13.5681 13.5781 14.8581 11.9981 14.8581C11.9381 14.8581 11.8881 14.8581 11.8281 14.8381L14.8381 11.8281C14.8581 11.8881 14.8581 11.9381 14.8581 11.9981Z"
        fill="currentColor"
      />
      <path
        d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z"
        fill="currentColor"
      />
    </svg>
  );



  export const EyeFilledIcon = ({width,height}) => (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height={height}
      role="presentation"
      viewBox="0 0 24 24"
      width={width}
    >
    <path
      d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
      fill="currentColor"
    />
    <path
      d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
      fill="currentColor"
    />
    </svg>
  );


  export const MailIcon = ({width,height}) => (
    <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height={height}
    role="presentation"
    viewBox="0 0 24 24"
    width={width}
    >
      <path
        d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
        fill="currentColor"
      />
    </svg>
  );

  export const LocationIcon = ({width,height})=>{
    return (
  <svg viewBox="0 0 24 24" width={width} height={height}  xmlns="http://www.w3.org/2000/svg" version="1.1" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g transform="translate(0 -1028.4)"> <path d="m8 1030.4 8 1v19l-8-1z" fill="#ecf0f1"></path> <path d="m2 1031.4 6-1v19l-6 1z" fill="#bdc3c7"></path> <path d="m16 1031.4 6-1v19l-6 1z" fill="#bdc3c7"></path> <path d="m3 1032.4 5-1v17l-5 1z" fill="#27ae60"></path> <path d="m8 1031.4 8 1v17l-8-1z" fill="#2ecc71"></path> <path d="m13 1c-1.657 0-3 1.3432-3 3s1.343 3 3 3 3-1.3432 3-3-1.343-3-3-3zm0 2c0.552 0 1 0.4477 1 1s-0.448 1-1 1-1-0.4477-1-1 0.448-1 1-1z" transform="translate(0 1028.4)" fill="#c0392b"></path> <path d="m21 1048.4-5 1v-17l5-1z" fill="#27ae60"></path> <path d="m5.6875 1031.8-2.3125 0.5 4.625 4.9v-2.9l-2.3125-2.5z" fill="#f39c12"></path> <path d="m21 1046.4-5 1v-6l5-3z" fill="#f39c12"></path> <path d="m21 1048.4-5 1v-6l5-3z" fill="#2980b9"></path> <path d="m8 1042.4 8-1v6l-8-1z" fill="#f1c40f"></path> <path d="m8 1044.4 8-1v6l-8-1z" fill="#3498db"></path> <path d="m3 1045.4 5-3v4l-5 1z" fill="#f39c12"></path> <path d="m3 1047.4 5-3v4l-5 1z" fill="#2980b9"></path> <path d="m8 8.8008v-2.8985l4 8.6597h-1.469z" transform="translate(0 1028.4)" fill="#f1c40f"></path> <path d="m13 1028.4c-2.209 0-4 1.8-4 4 0 0.7 0.1908 1.3 0.5156 1.9 0.0539 0.1 0.1105 0.2 0.1719 0.3l3.3125 5.8 3.312-5.8c0.051-0.1 0.095-0.2 0.141-0.2l0.031-0.1c0.325-0.6 0.516-1.2 0.516-1.9 0-2.2-1.791-4-4-4zm0 2c1.105 0 2 0.9 2 2s-0.895 2-2 2-2-0.9-2-2 0.895-2 2-2z" fill="#e74c3c"></path> </g> </g></svg>
   );
  };
  
  export const CalenderIcon = ({width,height})=>{
    return (
  <svg viewBox="0 0 120 120" id="Layer_1" width={width} height={height} version="1.1" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
      <g> 
    <g> <path fill="#F4F4F4" d="M99.3,96.3H20.5c-3.1,0-5.5-2.5-5.5-5.5V24.3c0-3.1,2.5-5.5,5.5-5.5h78.9c3.1,0,5.5,2.5,5.5,5.5v66.5 C104.9,93.8,102.4,96.3,99.3,96.3z"></path> 
    <path fill="#FF9DD7" d="M99.3,42.2H20.5c-3.1,0-5.5-2.5-5.5-5.5V24.3c0-3.1,2.5-5.5,5.5-5.5h78.9c3.1,0,5.5,2.5,5.5,5.5v12.4 C104.9,39.7,102.4,42.2,99.3,42.2z"></path> <g>
       <path fill="#FCC3E7" d="M34.2,50.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C35.4,51.4,34.9,50.8,34.2,50.8z"></path>
        <path fill="#FCC3E7" d="M54.7,50.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C56,51.4,55.4,50.8,54.7,50.8z"></path> 
        <path fill="#FCC3E7" d="M75.1,50.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C76.4,51.4,75.8,50.8,75.1,50.8z"></path> 
        <path fill="#FCC3E7" d="M34.2,64.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C35.4,65.4,34.9,64.8,34.2,64.8z"></path>
         <path fill="#FCC3E7" d="M54.7,64.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C56,65.4,55.4,64.8,54.7,64.8z"></path> 
         <path fill="#FCC3E7" d="M75.1,64.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C76.4,65.4,75.8,64.8,75.1,64.8z"></path>
          <path fill="#FCC3E7" d="M34.2,78.9h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C35.4,79.5,34.9,78.9,34.2,78.9z"></path>
           <path fill="#FCC3E7" d="M54.7,78.9h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C56,79.5,55.4,78.9,54.7,78.9z"></path>
            <path fill="#FCC3E7" d="M75.1,78.9h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C76.4,79.5,75.8,78.9,75.1,78.9z"></path> </g> <g> 
              <path fill="#FF92A1" d="M95.6,50.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C96.9,51.4,96.3,50.8,95.6,50.8z"></path> 
           <path fill="#FF92A1" d="M95.6,64.8h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C96.9,65.4,96.3,64.8,95.6,64.8z"></path> 
           <path fill="#FF92A1" d="M95.6,78.9h-9.9c-0.7,0-1.3,0.6-1.3,1.3v6.2c0,0.7,0.6,1.3,1.3,1.3h9.9c0.7,0,1.3-0.6,1.3-1.3v-6.2 C96.9,79.5,96.3,78.9,95.6,78.9z"></path> </g> <g>
            <path fill="#6DE8B1" d="M27.8,57.4c0.5,0,0.8-0.1,1.2-0.4l7-6.9c0.6-0.6,0.7-1.6,0.1-2.3c-0.6-0.6-1.6-0.7-2.3-0.1l-6,5.8l-3.4-3.8 C24,49,22.8,49,22.2,49.6c-0.7,0.5-0.7,1.6-0.1,2.3l4.5,5.1C27,57.2,27.3,57.4,27.8,57.4z"></path>
             <path fill="#6DE8B1" d="M57.9,61.3c-0.6-0.6-1.6-0.7-2.3-0.1l-5.9,5.7l-3.4-3.8c-0.5-0.7-1.6-0.7-2.3-0.1s-0.7,1.6-0.1,2.3l4.5,5.1 c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.4l7-6.9C58.4,62.9,58.5,61.9,57.9,61.3z"></path> 
             <path fill="#6DE8B1" d="M35.9,76.2L30,81.9l-3.4-3.8C26,77.4,25,77.4,24.3,78c-0.7,0.6-0.7,1.6-0.1,2.3l4.5,5.1 c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.4l7-6.9c0.6-0.6,0.7-1.6,0.1-2.3C37.5,75.7,36.5,75.6,35.9,76.2z"></path> </g> </g> <g>
               <rect fill="#5DC995" height="16.1" transform="matrix(0.8078 -0.5894 0.5894 0.8078 -33.7747 72.3989)" width="3.9" x="92.2" y="79.9"></rect> 
           <path fill="#6DE8B1" d="M104.6,98.3l-3.9,2.9c-0.2,0.2-0.5,0.1-0.7-0.1l-7.9-10.8c-0.2-0.2-0.1-0.5,0.1-0.7l3.9-2.9 c0.2-0.2,0.5-0.1,0.7,0.1l7.9,10.9C104.9,97.8,104.9,98.1,104.6,98.3z"></path> 
           <circle fill="#FDFEFF" cx="81.5" cy="70.8" r="12"></circle> 
           <path fill="#6DE8B1" d="M81.5,55.3C73,55.3,66,62.2,66,70.8S73,86.2,81.5,86.2S97,79.3,97,70.8S90.1,55.3,81.5,55.3z M81.5,82.8 c-6.6,0-12-5.4-12-12s5.4-12,12-12s12,5.4,12,12S88.2,82.8,81.5,82.8z"></path> </g> </g> </g></svg>
   );
  };
  
  
  
  
  export const CalenderIcon2 = ({width,height})=>{
    return (
  <svg viewBox="0 0 24 24" width={width} height={height}  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <defs> <path id="calendar-a" d="M0,0 L18,0 L18,2 L0,2 L0,0 Z M0,11 L18,11 L18,13 L0,13 L0,11 Z"></path> <path id="calendar-c" d="M2,7 L18,7 L18,4 L16,4 L16,2 L18,2 C19.1045695,2 20,2.8954305 20,4 L20,18 C20,19.1045695 19.1045695,20 18,20 L2,20 C0.8954305,20 0,19.1045695 0,18 L0,4 C0,2.8954305 0.8954305,2 2,2 L4,2 L4,4 L2,4 L2,7 Z M2,9 L2,18 L18,18 L18,9 L2,9 Z M7,4 C7,4.55228475 6.55228475,5 6,5 C5.44771525,5 5,4.55228475 5,4 L5,1 C5,0.44771525 5.44771525,0 6,0 C6.55228475,0 7,0.44771525 7,1 L7,4 Z M15,4 C15,4.55228475 14.5522847,5 14,5 C13.4477153,5 13,4.55228475 13,4 L13,1 C13,0.44771525 13.4477153,0 14,0 C14.5522847,0 15,0.44771525 15,1 L15,4 Z M8,2 L12,2 L12,4 L8,4 L8,2 Z M7,10 L9,10 L9,12 L7,12 L7,10 Z M11,10 L13,10 L13,12 L11,12 L11,10 Z M15,10 L17,10 L17,12 L15,12 L15,10 Z M3,14 L5,14 L5,16 L3,16 L3,14 Z M7,14 L9,14 L9,16 L7,16 L7,14 Z M11,14 L13,14 L13,16 L11,16 L11,14 Z M15,14 L17,14 L17,16 L15,16 L15,14 Z"></path> </defs> <g fill="none" fill-rule="evenodd" transform="translate(2 2)"> <g transform="translate(1 6)"> <mask id="calendar-b" fill="#ffffff"> <use xlinkHref="#calendar-a"></use> </mask> <use fill="#D8D8D8" xlinkHref="#calendar-a"></use> <g fill="#FFA0A0" mask="url(#calendar-b)"> <rect width="24" height="24" transform="translate(-3 -8)"></rect> </g> </g> <mask id="calendar-d" fill="#ffffff"> <use xlinkHref="#calendar-c"></use> </mask> <use fill="#000000" fill-rule="nonzero" xlinkHref="#calendar-c"></use> <g fill="#7600FF" mask="url(#calendar-d)"> <rect width="24" height="24" transform="translate(-2 -2)"></rect> </g> </g> </g></svg>
   );
  };