
import FFL from '../../src/images/pdf/FFL.png';
import img1 from '../../src/images/pdf/Picture3.png';

export const engineeringData = [
    {
      description:'Engineering Plan Approval (EPA) is formal permission required for civil engineering works that create public infrastructure, like stormwater and wastewater pipes, managed by the local council. EPAs ensure these public assets are designed safely and efficiently, helping reduce future maintenance costs. Minor Approvals cover simpler works (e.g., stormwater connections), while Major Approvals are for complex projects (e.g., road widening). Applications for EPAs often require a NZ Chartered Professional Engineer or a professional surveyor and can be submitted alongside a resource consent.',
      description1:'EPA is your formal approval for civil works like stormwater pipes or roads—think of it as your “infrastructure traffic signal” for designing safe and cost-effective public systems! 📐',
      name: "ENGINEERING PLAN APPROVALS (EPA)",
      src:FFL,
      title:"ENGINEERING"
    },
    {
        description:'A Vehicle Crossing Application is required when planning to build or modify a vehicle crossing, the section of a driveway connecting a public road to private property, often replacing part of the public footpath. This application must be submitted and approved before any work begins. All vehicle crossings on existing roads within subdivisions must follow this process. Once approved, an inspection can be booked through the online booking tool.',
        description1:'A Vehicle Crossing Application is your official approval to connect your driveway to a public road. It’s like building a safe, smooth “runway” for your car! 🚗',
        name: "VEHICLE CROSSING APPLICATION",
        src:img1,
        title:"ENGINEERING"
      },
      {
        description:'A Code Compliance Certificate (CCC) is a formal document issued by a building consent authority confirming that building work complies with the approved building consent and the Building Code. It verifies that any specified systems within the building can perform to the required standards. While the CCC concludes the consent process, buildings with specified systems must obtain annual building warrants of fitness. Retaining the CCC is important for future reference, such as when selling a home or applying for financing or insurance.',
        description1:'A CCC is your building’s “seal of approval,” proving it complies with safety standards. Think of it as your structure’s official report card for future security! 📋✅',
        name: "CODE COMPLIANCE CERTIFICATE (CCC)",
        src:FFL,
        title:"ENGINEERING"
      },
      {
        description:'Flooding refers to the inundation of land that is normally dry due to excessive rainfall, stormwater runoff, or the overflow of water bodies. It can occur as a result of various factors, including heavy rainfall, storm surges, or the failure of drainage systems. Flooding can have significant impacts on land use, infrastructure, and the environment, and is a critical consideration in planning and development.',
        description1:"Flooding is when excess rain or water overflow turns dry land into a temporary swimming pool. Proper planning ensures your property stays high and dry! 🌊",
        name: "FLOODING",
        src:img1,
        title:"ENGINEERING"
      },
      {
        description:"An Overland Flow Path (OFLP) is a designated route for stormwater runoff to flow overland during periods of heavy rainfall.These paths are typically identified in flood management plans to ensure that stormwater is effectively managed and directed away from developed areas to minimize flooding risks. Maintaining the integrity of OFLPs is essential for managing flooding and protecting property and infrastructure.",
        description1:'An OFLP is a planned stormwater pathway that helps guide heavy rainfall safely away from properties, protecting homes and infrastructure. 🌧',
        name: "OVERLAND FLOW PATH (OFLP)",
        src:FFL,
        title:"ENGINEERING"
      },
    
      {
        name: "FLOOD SENSITIVE AREA",
        description:'A Flood Sensitive Area is a designated zone identified in planning documents where the risk of flooding is higher due to its topography, proximity to water bodies, or other environmental factors. Development within these areas may be subject to specific regulations and controls to mitigate flooding risks and manage stormwater effectively. These areas are often subject to additional assessments and restrictions to ensure the safety and resilience of new developments.',
        description1:'A Flood Sensitive Area is a high-risk flood zone where extra measures are needed to ensure development remains safe and resilient against water damage.🌧',
        src:img1,
        title:"ENGINEERING"
      },
      {
        name: "FFL (FINISHED FLOOR LEVEL)",
        description:"Finished Floor Level (FFL) refers to the final elevation of a building's floor after construction is complete. It is measured relative to a specific reference point, such as ground level or a defined datum, and is important for ensuring proper drainage, accessibility, and compliance with flood risk management standards.",
        description1:'FFL is the final elevation of your building’s floor, ensuring it’s high enough for proper drainage and flood safety. It’s the foundation’s “height checkpoint”! 📏🏠',
        src:FFL,
        title:"ENGINEERING"
      },
      {
        name: "FGL (FINISHED GROUND LEVEL)",
        description:"Finished Ground Level (FGL) is the final elevation of the ground surface after earthworks and landscaping are completed. This level is crucial for determining the site's suitability for development, drainage, and flood management, and is typically measured relative to a specified datum or existing ground level.",
        description1:'FGL is the final, precise height of the ground after landscaping and earthworks, ensuring a safe and stable foundation for your property. 🌍',
        src:img1,
        title:"ENGINEERING"
      },
    
      {
        name: "RL (REDUCED LEVEL)",
        description:'Reduced Level (RL) is a term used in surveying and civil engineering to denote the elevation of a specific point relative to a standard reference point, usually sea level or a local benchmark. It is essential for ensuring accurate measurements in construction, grading, and drainage design.',
        description1:'RL is the precise elevation of a point compared to a reference level, like sea level—a vital guide for accurate grading and construction alignment.📍🗺',
        src:FFL,
        title:"ENGINEERING"
      },
      {
        name:"GRADIENT",
        description:"Gradient refers to the slope or steepness of a surface, typically expressed as a ratio, percentage, or degree. It is important for managing stormwater runoff, ensuring accessibility, and complying with engineering standards. A suitable gradient helps prevent erosion and flooding by directing water flow appropriately.",
        description1:"Gradient is the slope of a surface, guiding water flow and ensuring accessibility. Think of it as your land’s natural drainage map!",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"COASTAL INUNDATION",
        description:"Coastal inundation refers to the flooding of coastal areas due to high tides, storm surges, or extreme weather events. It can result in the temporary or permanent submergence of land, affecting infrastructure, ecosystems, and communities along the coast.",
        description1:"Coastal Inundation occurs when the ocean temporarily floods coastal areas due to tides or storms. Smart planning ensures the waves stay where they belong!",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SOIL INVESTIGATION TESTS (SITS)",
        description:"Soil Investigation Tests (SITs) are a series of assessments conducted to determine the physical and chemical properties of soil at a site. These tests help evaluate soil stability, load-bearing capacity, drainage characteristics, and suitability for construction. Common tests include sampling, compaction tests, shear strength tests, and moisture content analysis. The results inform engineering and design decisions, ensuring that construction projects are built on suitable soil conditions.",
        description1:"SITs assess your soil’s stability and suitability for construction, ensuring your project is grounded on a solid, reliable foundation.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"CLAY",
        description:"Properties: Fine particles (<0.002 mm), high plasticity, and significant water retention. Characteristics: Low permeability, high compressibility, excellent nutrient retention; may lead to drainage issues and settlement problems in construction.",
        description1:"Clay has fine particles and retains water, making it nutrient-rich but challenging for drainage and construction.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SILT",
        description:"Properties: Particles (0.002 mm to 0.063 mm), smooth texture, moderate moisture retention. Characteristics: Moderate plasticity, prone to erosion when dry, and often fertile; requires careful management during excavation.",
        description1:"Silt has smooth, fertile particles that hold water moderately well but can erode easily without proper management.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SAND",
        description:"Properties: Larger particles (0.063 mm to 2 mm), gritty texture, low water retention. Characteristics: High permeability allows for quick drainage; typically lower in nutrients, providing good structural support but may need stabilization in loose conditions.",
        description1:"Sand has gritty particles, drains quickly, and offers structural support, but loose sand may need stabilization.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"LOAM",
        description:"Properties: Balanced mixture of sand, silt, and clay, moderate water retention. Characteristics: High fertility, excellent drainage, easy to work with; ideal for agriculture and gardening.",
        description1:"Loam is a balanced soil with excellent drainage and fertility, ideal for agriculture and easy to manage.✨",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"PEAT",
        description:"Properties: Organic material from decayed plant matter, high moisture content, low density. Characteristics: Highly compressible, poor drainage; beneficial as a soil amendment but presents challenges for construction due to variable load-bearing capacity.",
        description1:"Peat is moisture-rich and compressible, excellent for gardening but challenging for building foundations.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"ALLOPHANIC SOIL",
        description:"Properties: High levels of allophane clay derived from volcanic ash, good nutrient retention. Characteristics: Well-draining, often fertile; varying strength requires careful assessment in construction.",
        description1:"Allophanic Soil is nutrient-rich and well draining but requires testing for construction strength.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SALINE SOIL",
        description:"Properties: High levels of soluble salts, poor drainage. Characteristics: Can restrict plant growth and affect vegetation health; requires specific management practices to reduce salinity.",
        description1:"Saline Soil contains soluble salts that hinder growth, needing careful management to reduce salinity.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"ALKALINE SOIL",
        description:"Properties: High pH levels (above 7), limited nutrient availability. Characteristics: Can cause nutrient deficiencies; may require amendments for successful agricultural use.",
        description1:"Alkaline Soil has high pH levels and limited nutrients, requiring amendments to support plant growth.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SMAF1 & SMAF2 (STORMWATER MANAGEMENT ASSESSMENT FRAMEWORK)",
        description:"SMAF1 and SMAF2 are categories within the Stormwater Management Assessment Framework that provide guidance on assessing and managing stormwater impacts in development projects. SMAF1 typically applies to smaller, less complex developments, while SMAF2 is used for larger or more complex projects that require detailed assessment and mitigation strategies.",
        description1:"SMAF1 and SMAF2 guide stormwater management, with tailored solutions for small (SMAF1) and large (SMAF2) projects. ☔",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"RETENTION + DETENTION OF STORMWATER",
        description:"Retention refers to the practice of storing stormwater on-site for extended periods, allowing for gradual release and reducing peak runoff. Detention, on the other hand, involves temporarily holding stormwater in a facility (e.g., detention basin) to control the rate of discharge into receiving waters, minimizing flooding and erosion downstream.",
        description1:"Retention stores water for later use, while detention slows it down temporarily—both ensuring safe water management.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"HIRB (HIGH-INTENSITY RAINFALL BUFFER)",
        description:"HIRB refers to a design approach that considers the effects of high-intensity rainfall events on stormwater management systems. It aims to mitigate the impacts of such events by incorporating buffer zones or additional storage capacity to handle increased runoff.",
        description1:"HIRB adds capacity to handle heavy rain events, ensuring your stormwater systems don’t overflow.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"IMPERVIOUS AREA",
        description:"An impervious area is a surface that does not allow water to infiltrate, such as concrete, asphalt, or roofs. These surfaces contribute to increased stormwater runoff, leading to potential flooding and water quality issues.",
        description1:"Impervious Areas are surfaces that block water from seeping in, leading to runoff that needs careful management.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"PERMEABLE AREA",
        description:"A permeable area is a surface that allows water to infiltrate into the ground, such as grass, gravel, or permeable pavers. These areas help reduce stormwater runoff and promote groundwater recharge, improving overall site drainage.",
        description1:"Permeable infiltration, runoff Areas reducing allow water stormwater and promoting natural groundwater recharge.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"DRAIN BRIDGING OVER STORMWATER AND SEWER SYSTEM",
        description:"Drain bridging refers to the design requirement for drainage systems to span over existing stormwater and sewer infrastructure without obstructing their function. Proper clearance and structural support must be maintained to ensure both drainage and utility systems operate effectively.",
        description1:"Drain Bridging ensures drainage systems cross other utilities without interference, maintaining smooth flow.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"WORKS OVER APPROVAL FOR SEWER SYSTEMS FROM WATERCARE",
        description:"Works Over Approval is a formal permission required from Watercare when conducting construction or development activities over or near existing sewer infrastructure. This approval ensures that the integrity and function of the sewer system are not compromised during construction.",
        description1:"Works Over Approval ensures nearby construction won’t disturb sewer systems, protecting critical infrastructure.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"KERB DISCHARGE",
        description:"Kerb discharge is a stormwater management technique where runoff is directed from the road kerb into adjacent green areas, such as swales or rain gardens, for treatment and infiltration. This practice helps manage stormwater and improve water quality.",
        description1:"Kerb Discharge directs road runoff to nearby green spaces, filtering and managing stormwater naturally.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"RECHARGE PIT",
        description:"A recharge pit is a constructed facility designed to enhance groundwater recharge by collecting and infiltrating stormwater runoff. These pits typically consist of a deep excavation filled with gravel or permeable material, allowing water to percolate into the underlying soil.",
        description1:"Recharge Pits enhance groundwater levels by collecting stormwater and letting it soak back into the earth.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SOAKING PIT",
        description:"A soaking pit is a type of infiltration system used to manage stormwater by allowing it to soak into the ground. These pits are typically filled with gravel or similar materials and are designed to receive and retain runoff, promoting groundwater recharge.",
        description1:"Soaking Pits are gravel-filled systems that let stormwater seep into the ground, reducing runoff and recharging the soil.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"FIREWALL",
        description:"A firewall is a fire-resistant barrier designed to prevent the spread of fire between buildings or within different sections of a building. It is constructed with non-combustible materials and must meet specific building code requirements to ensure fire safety.",
        description1:"Firewalls are fire-resistant barriers that prevent fire from spreading, ensuring building safety. Think of them as a superhero’s force field!",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"INTENSITY WALL",
        description:"An intensity wall is a structural element designed to withstand lateral loads from wind or seismic activity, providing stability to buildings, particularly in high-intensity zones. It is engineered to maintain structural integrity during extreme weather events or earthquakes.",
        description1:"Intensity Walls are structural reinforcements that protect buildings against wind and earthquakes, like having a personal bodyguard for stability.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"IMPERVIOUS LAYER",
        description:"A surface, such as concrete or asphalt, that water cannot penetrate, increasing runoff to drains or nearby land.",
        description1:"Impervious Layers are water-blocking surfaces, like asphalt, that direct rainwater elsewhere, often increasing runoff risks.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"RAINWATER TANK",
        description:"A tank used to collect and store rainwater, often for garden or non-drinking water use, helping reduce water demand.",
        description1:"Rainwater Tanks collect and store rainwater, providing eco-friendly water solutions for gardens and household use. 🏡",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"STORMWATER",
        description:"Rainwater that runs off surfaces like roofs, roads, and driveways. It flows into drains, creeks, or rivers, often untreated, and is managed to prevent flooding and pollution in natural waterways.",
        description1:"Stormwater is rain runoff from surfaces like roofs and roads, managed to prevent flooding and keep waterways clean.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"WASTEWATER",
        description:"Used water from homes, businesses, and industries, including sewage and water from sinks, showers, and toilets. It is treated before being released back into the environment to protect public health and water quality.",
        description1:"Wastewater is used water, treated to ensure it’s safe before returning to the environment, protecting health and nature. 🌍",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"OVERLAND FLOW PATHS",
        description:"Natural or designated pathways that carry rainwater runoff across land to prevent flooding, channeling excess water safely toward streams, drains, or other waterways.",
        description1:"Overland Flow Paths safely guide rainwater across land, preventing floods and protecting infrastructure. ",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"CATCHMENT",
        description:"An area of land where all rainfall and water flow drain into a single water body, such as a river, lake, or reservoir. It’s a natural boundary for managing water resources and understanding water movement.",
        description1:"Catchments are areas where water collects and drains into a single body, helping manage and understand water flow.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"OPEN WATERCOURSE",
        description:"A natural or man-made channel, like a stream or river, that carries water on the surface and is exposed to the air.",
        description1:"Open Watercourses are exposed channels like streams that safely carry water, keeping flow visible and natural.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"PIPED WATERCOURSE",
        description:"A watercourse that has been redirected into underground pipes, often in urban areas, to manage water flow more effectively without open exposure.",
        description1:"Piped Watercourses redirect water through underground pipes, managing flow discreetly in urban environments.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"CULVERT",
        description:"A tunnel or pipe that allows water to pass under a road, trail, or railway, maintaining natural water flow and reducing erosion or flooding.",
        description1:"Culverts are tunnels that guide water under roads or trails, protecting structures and preventing floods.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"VEHICLE ACCESS",
        description:"The designated entry and exit points for vehicles on a property, allowing safe and legal entry to roads or driveways.",
        description1:"Vehicle Access ensures safe and legal pathways for vehicles to enter or exit your property, acting as a well-designed gateway.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"VEHICLE CROSSING",
        description:"A section of the road, typically a curb or pavement, modified to allow vehicles to cross into a property, commonly seen at driveways.",
        description1:"A Vehicle Crossing modifies the road for safe car access into a property—essential for smooth driveway connections. 🏠",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"MANHOLE",
        description:"An access point in the ground for maintenance and inspection of underground utilities, such as sewage, stormwater, and electrical systems.",
        description1:"A Manhole provides access to underground utilities, ensuring maintenance and repairs are easy to perform.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SS LINE (SANITARY SEWER LINE)",
        description:"Pipes that carry wastewater from buildings to treatment facilities, keeping it separate from stormwater systems.",
        description1:"SS Lines are pipelines that safely transport wastewater to treatment plants, keeping the system clean and separate.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"SW LINE (STORMWATER LINE)",
        description:"Pipes that carry rainwater runoff away from roads and properties to prevent flooding, directing it to natural water bodies.",
        description1:"SW Lines channel stormwater runoff safely to nearby water bodies, helping prevent floods in urban areas. 🚰",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"IL LINE (INVERT LEVEL LINE)",
        description:"The lowest point or inner bottom of a pipe, channel, or manhole, used in engineering to ensure proper water flow.",
        description1:"IL Lines represent the inner bottom of pipes or channels, ensuring water flows smoothly through the system.",
        src:"",
        title:"ENGINEERING"
      },
      {
        name:"EARTHWORKS",
        description:"Any activity that involves moving, cutting, filling, or grading soil and rock to change the land’s shape or level, often done for construction or landscaping.",
        description1:"Earthworks involve reshaping land through soil movement, making it ready for construction or landscaping projects. ✂",
        src:"",
        title:"ENGINEERING"
      }
];

export const architectureData = [
    {
        name: "ZERO LOT BOUNDARY",
        description:' Zero lot boundary design refers to a building configuration where a structure is built right up to one or more property lines, with little or no space between the building and the boundary. This approach maximizes the use of available land and can enhance privacy for adjacent properties, but may restrict natural light and airflow.',
        description1:' A Zero Lot Boundary design maximizes land use by building right up to the property line. It’s the ultimate way to claim every inch of your plot, with trade-offs in airflow and light.',
        src:'',
        title:"ARCHITECTURE"
          },
        {
      name:"STAND ALONE",
      description:"Stand-alone architecture design refers to a structure that is independent and not attached to any other building. This typology often allows for greater privacy and outdoor space, as the building is surrounded by open land. Stand-alone designs are commonly found in single-family homes and can vary in size and style.",
      description1:"A Stand Alone building is an independent structure, like having your own little bubble of space and privacy, perfect for personalizing your dream home!",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"TERRACE HOUSING",
      description:"Terrace housing, also known as row housing or townhouses, consists of a series of attached homes that share one or more walls. Each unit typically has its own entrance and may include small front and rear yards. This design promotes efficient land use while providing a sense of community and affordability.",
      description1:"Terrace Housing combines affordability and space efficiency, offering shared walls but private entrances for a great mix of community and personal space.",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"APARTMENTS",
      description:"Apartments are residential units within a larger building or complex, typically designed for multiple households. These units can vary in size and layout, and residents share common facilities such as hallways, elevators, and recreational areas. Apartment living is often characterized by its focus on convenience and access to amenities.",
      description1:"Apartments are stacked residential units in larger buildings, offering shared spaces and easy access to amenities—ideal for modern, convenient living! ✨",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"MIXED-USE RESIDENTIAL + COMMERCIAL",
      description:"Mixed-use residential and commercial design refers to developments that combine residential living spaces with commercial or retail areas within the same building or site. This typology encourages a vibrant community atmosphere, reduces the need for commuting, and provides residents with convenient access to services and amenities.",
      description1:"A Mixed-Use Residential + Commercial design blends living spaces with retail or business zones, creating vibrant communities and cutting down on commute time. ☕",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"DWELLING",
      description:"Any building or part of a building where people live, such as houses or apartments.",
      description1:"A Dwelling is a living space, from apartments to houses, where you can feel at home. It’s where life happens!",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"LANDSCAPED AREA",
      description:"Portions of a property with natural or designed plantings like grass, shrubs, or gardens, enhancing aesthetic and environmental value.",
      description1:"Landscaped Areas bring beauty and environmental benefits to your property with green spaces and thoughtful plantings. 🌸",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"OUTLOOK SPACE",
      description:"Designated areas with a clear view (such as windows or balconies) from inside a dwelling, providing natural light and ventilation.",
      description1:"Outlook Space ensures your home gets natural light and ventilation, acting like a built-in window to the world!",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"OUTDOOR LIVING SPACE",
      description:"Private, usable outdoor areas, like patios or yards, associated with each dwelling, allowing for outdoor activities.",
      description1:"Outdoor Living Space gives you private outdoor areas for relaxation or recreation, perfect for sunny days and fres",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"MINOR DWELLING",
      description:"A smaller secondary dwelling on a property, often limited to a specific floor area, designed to be less prominent than the main house.",
      description1:"A Minor Dwelling is a compact, secondary home that fits right into your property, offering extra living space.",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"HEIGHT",
      description:"The vertical distance from the ground to the top of a structure, regulated to maintain neighborhood consistency and light access.",
      description1:"Height is the regulated vertical distance of a building, keeping the skyline balanced and the neighbors happy.",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"HEIGHT IN RELATION TO BOUNDARY",
      description:"This rule restricts how tall buildings can be close to property boundaries to avoid overshadowing and maintain privacy between neighboring properties. It specifies a maximum height that buildings can reach at the boundary, with height allowances increasing as the building moves further from the boundary. This ensures neighbors still receive sufficient sunlight and have a level of privacy.",
      description1:"Height in Relation to Boundary keeps buildings tall but considerate, ensuring sunlight and privacy for all neighbors. ☀",
      src:"",
      title:"ARCHITECTURE"
    },
    {
      name:"YARD",
      description:"",
      description1:"A Yard is the open space around your property —front, side, or by the water—balancing beauty and environmental care.",
      src:"",
      title:"ARCHITECTURE"
    }
  ];
  
  export const planningData = [
    {
        name: "BUILDING CONSENT",
        description:' A building consent is formal approval from your local council to carry out specific construction work. It ensures the project meets safety standards and complies with building regulations. Most construction projects in New Zealand require a building consent, though some low-risk work may be exempt. Consent must be obtained before starting any work, as failure to do so can result in penalties, even if approval is sought afterward.',
        description1:' A building consent is your council’s official stamp of approval, ensuring your construction meets safety standards—think of it as getting permission to build a science project that won’t collapse!',
        src:'',
        title:"PLANNING"
      },

    {
      name:"RESOURCE CONSENT",
      description:"A resource consent is official permission from your local council to carry out activities that may impact the environment or other people. It ensures that potential effects are managed properly. Resource consents are often required for projects like building houses, land development, water use, or activities that may affect water, soil, or air quality. Conditions may be added to help reduce any negative impact of the project.",
      description1:"A resource consent is the council’s way of managing projects that impact the environment, like making sure your “party” doesn’t get too loud for the neighbors—or nature!",
      src:"",
      title:"PLANNING"
    },
    {
      name:"BUILDING CONSENT VS. RESOURCE CONSENT",
      description:"Building consents and resource consents serve different purposes. Every new home project requires a building consent, but not all require a resource consent. A resource consent is necessary if your project breaches specific zoning rules (e.g., building height, site coverage) set by your District Council. Resource consents are handled by the planning team, while building consents are managed by the building team.",
      description1:"Building Consent ensures your project is safe and strong, while Resource Consent ensures it plays by the zoning rules. Together, they’re the dynamic duo that makes your construction dreams come true!",
      src:"",
      title:"PLANNING"
    },    {
      name:"LAND USE APPLICATION",
      description:"A Land Use Application is the formal request submitted to a local council in New Zealand for permission to establish a new business, activity, or construct buildings on a specific piece of land. This application is necessary to obtain Land Use Consent, ensuring that the proposed use complies with local zoning regulations and the Resource Management Act (RMA). The application process typically requires detailed information about the intended use, environmental impacts, and how the project aligns with regional and district plans.",
      description1:"A Land Use Application is like sending a formal RSVP to the council for your land’s next purpose. It makes sure your plans align with zoning rules and environmental standards!",
      src:"",
      title:"PLANNING"
    },    {
      name:"LAND USE CONSENT",
      description:"Land Use Consent is a type of resource consent required in New Zealand for individuals or developers who want to establish a new business, activity, or construct buildings on land. This consent is typically necessary to ensure that the proposed use of the land aligns with zoning regulations outlined in the Auckland Unitary Plan (AUP) and complies with the Resource Management Act (RMA). It ensures that land is used in a way that is appropriate and sustainable for the surrounding environment.",
      description1:"A Land Use Consent ensures your land use complies with zoning rules and stays sustainable. It’s like getting a green thumbs-up for your land’s next adventure!",
      src:"",
      title:"PLANNING"
    },    {
      name:"BUNDLED CONSENT",
      description:"Bundled consent refers to a single application that seeks approval for multiple resource consents or planning permissions simultaneously. This approach allows developers or applicants to address various aspects of their project, such as land use, building consent, and environmental effects, in one comprehensive request. While this can streamline the approval process, it is essential for applicants to provide sufficient detail regarding each aspect of the proposal, ensuring that all relevant conditions and impacts are adequately considered by the council.",
      description1:"A Bundled Consent lets you handle all necessary approvals in one go, streamlining the process and keeping your project efficient—like ordering a combo meal for your development! 🍔",
      src:"",
      title:"PLANNING"
    },  
    {
      name:"CODE COMPLIANCE CERTIFICATE (CCC)",
      description:"A Code Compliance Certificate (CCC) is a formal document issued by a building consent authority confirming that building work complies with the approved building consent and the Building Code. It verifies that any specified systems within the building can perform to the required standards. While the CCC concludes the consent process, buildings with specified systems must obtain annual building warrants of fitness. Retaining the CCC is important for future reference, such as when selling a home or applying for financing or insurance.",
      description1:"A CCC is your building’s “seal of approval,” proving it complies with safety standards. Think of it as your structure’s official report card for future security! ✅",
      src:"",
      title:"PLANNING"
    },    {
      name:"AEP 1% (ANNUAL EXCEEDANCE PROBABILITY)",
      description:"AEP 1% refers to the statistical likelihood that a particular rainfall event or flood will be equaled or exceeded in any given year, specifically a 1% chance (or 1 in 100) annually. This metric is used in flood risk assessment and planning to identify areas prone to significant flooding events.",
      description1:"AEP 1% predicts the chance of a significant flood in any year—a 1% chance that keeps you prepared for rare but big rain events. 📈",
      src:"",
      title:"PLANNING"
    },    {
      name:"AUCKLAND DESIGN MANUAL (KDL REQUIREMENTS)",
      description:"The Auckland Design Manual provides guidelines and standards for design quality in Auckland's built environment. KDL (Key Design Layers) requirements outline specific design considerations that developers must adhere to, including aspects of site layout, landscaping, building materials, and integration with the surrounding context.",
      description1:"The KDL Requirements are Auckland’s blueprint for quality property design, covering layout, materials, and landscaping.",
      src:"",
      title:"PLANNING"
    },    {
      name:"BUILDING COVERAGE",
      description:"Building coverage refers to the portion of a site that is occupied by buildings and structures, expressed as a percentage of the total site area. It is a critical planning consideration that helps regulate density and ensure adequate open space.",
      description1:"Building Coverage determines the percentage of your land occupied by structures, helping maintain a balance between development and open space. 📏",
      src:"",
      title:"PLANNING"
    },    {
      name:"AVAILABLE TO BUILD/BUILDABILITY",
      description:"Available to build (or buildability) refers to the assessment of a site’s potential for development based on factors such as zoning regulations, topography, infrastructure availability, and environmental constraints. This evaluation determines whether a site can accommodate a proposed development.",
      description1:"Buildability assesses if your site is development-ready by analyzing zoning, topography, and environmental factors. Think of it as a land fitness check! 🌱",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESOURCE MANAGEMENT ACT 1991 (RMA)",
      description:"A New Zealand law focused on protecting the environment while allowing sustainable land and resource use, ensuring developments do not harm natural or community resources.",
      description1:"The RMA balances development with environmental protection, acting as a guideline to ensure sustainable growth. 📜",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESTRICTED DISCRETIONARY ACTIVITY",
      description:"Activities that require council consent but where the council only considers certain factors when deciding.",
      description1:"A Restricted Discretionary Activity requires council approval, but only certain criteria are assessed for a fair review.",
      src:"",
      title:"PLANNING"
    },  
    {
      name:"PERMITTED ACTIVITY",
      description:"Activities allowed without council consent as long as they meet certain standards.",
      description1:"A Permitted Activity allows you to proceed without council consent, as long as all required standards are followed. ✅",
      src:"",
      title:"PLANNING"
    }, 
    {
      name:"DISCRETIONARY ACTIVITY",
      description:"Activities requiring council consent, where the council has full discretion to approve or decline based on broad factors.",
      description1:"A Discretionary Activity is subject to full council discretion, with decisions based on a wide range of factors. ",
      src:"",
      title:"PLANNING"
    }, 
    {
      name:"AEP FLOOD PLAIN",
      description:"An area with a specified probability of flooding in any given year, such as a 1% AEP flood plain, which has a 1% chance of flooding annually (often called a '100-year flood' area).",
      description1:"An AEP Flood Plain is a designated high-risk zone with a 1% yearly chance of flooding—rare but impactful when it happens!",
      src:"",
      title:"PLANNING"
    }, 
    {
      name:"FREEHOLD",
      description:"A type of property ownership where the owner has full ownership of the land and any buildings on it indefinitely. This gives the owner maximum control over the property, unlike leasehold, which is limited by a lease term.",
      description1:"Freehold means owning your property outright, giving you full control and indefinite ownership of both land and buildings. ",
      src:"",
      title:"PLANNING"
    }, 
    {
      name:"FLOOD PLAINS",
      description:"Low-lying areas next to rivers or streams that are prone to flooding when water levels rise. These areas naturally absorb excess water, reducing flood risk downstream.",
      description1:"Flood Plains are low-lying areas that absorb floodwaters, protecting downstream areas from overflow damage. ",
      src:"",
      title:"PLANNING"
    }, 
    {
      name:"FLOOD PRONE AREAS",
      description:"Regions likely to experience flooding based on topography, climate, or drainage patterns, where flooding can disrupt life, infrastructure, or the environment.",
      description1:"Flood Prone Areas are regions at higher flood risk, requiring extra planning to protect infrastructure and lives.",
      src:"",
      title:"PLANNING"
    }, 
    {
      name:"FLOOD SENSITIVE AREAS",
      description:"Areas that may flood more easily due to specific site conditions, such as low-lying locations, limited drainage, or soil that absorbs water poorly. These areas often need special planning or restrictions to minimize flood risk.",
      description1:"Flood Sensitive Areas are high-risk flood zones needing careful planning to reduce flood risks and protect developments.",
      src:"",
      title:"PLANNING"
    }, 
    {
      name:"HYDROLOGY MAP",
      description:"A map showing the distribution and flow of water bodies (such as rivers, lakes, and groundwater) in an area, helping to understand water movement and flood risks.",
      description1:"Hydrology Maps chart the flow and locations of water, helping plan developments while managing water movement. 💦",
      src:"",
      title:"PLANNING"
    },    {
      name:"GEOTECHNICAL MAP",
      description:"A map that displays soil and rock conditions, stability, and characteristics of land, which is useful for construction and environmental planning.",
      description1:"GeoTechnical Maps show land conditions like soil and rock stability, ensuring safe construction and planning.",
      src:"",
      title:"PLANNING"
    },    {
      name:"AUCKLAND UNITARY PLAN",
      description:"A comprehensive plan that governs land use and development in Auckland, setting out rules and guidelines for sustainable growth, environmental protection, and community wellbeing.",
      description1:"The Auckland Unitary Plan is Auckland’s guide for sustainable growth, balancing land use, environment, and community needs. 📖",
      src:"",
      title:"PLANNING"
    },   {
      name:"ROW (RIGHT OF WAY)",
      description:"A legal right to pass through another person’s property, often used for road or utility access.",
      description1:"ROW (Right of Way) grants legal permission to use someone’s property for access, like a path shared with neighbors.",
      src:"",
      title:"PLANNING"
    },    {
      name:"CAW (COMMON ACCESS WAY)",
      description:"Shared access paths or driveways that allow multiple property owners or residents to reach their buildings",
      description1:"CAW (Common Access Way) is a shared path or driveway for property owners, keeping things accessible for everyone.",
      src:"",
      title:"PLANNING"
    },    {
      name:"EPA (ENVIRONMENTAL PROTECTION AUTHORITY)",
      description:"In New Zealand, the EPA is a government agency responsible for managing and protecting the environment. It oversees various environmental issues, such as controlling hazardous substances, regulating emissions, protecting natural resources, and setting standards for clean water, waste disposal, and air quality. The EPA ensures that development and industrial activities meet environmental standards to safeguard human health and ecosystems.",
      description1:"The EPA ensures that development projects meet strict environmental standards, safeguarding ecosystems and public health.",
      src:"",
      title:"PLANNING"
    },    {
      name:"EPA STORMWATER",
      description:"Guidelines or permits from environmental authorities (like the EPA) regulating stormwater management to prevent pollution and manage water flow.",
      description1:"EPA Stormwater permits set rules to manage rainwater, reducing pollution and ensuring safe flow to natural waterways.",
      src:"",
      title:"PLANNING"
    },    {
      name:"EPA WASTEWATER",
      description:"Regulations or permits from environmental authorities (such as the EPA) overseeing the treatment and disposal of wastewater to protect public health and the environment.",
      description1:"EPA Wastewater rules ensure that all used water is safely treated and disposed of, protecting health and the environment.",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESIDENTIAL – LARGE LOT ZONE",
      description:"This zone is intended for spacious residential living on large properties. It's typically for homes with more land area compared to standard urban lots, offering a more rural feel while still being within city boundaries. Large lot sizes help maintain open space and separation between homes.",
      description1:"",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESIDENTIAL – RURAL AND COASTAL SETTLEMENT ZONE",
      description:"This zone applies to settlements in more rural and coastal areas. It's meant to accommodate low density housing while protecting the natural environment, including coastal areas and rural landscapes. Development is usually more spread out, and building restrictions can help preserve scenic and ecological values.",
      description1:"",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESIDENTIAL – SINGLE HOUSE ZONE",
      description:"This zone is designed for one house per lot. It focuses on maintaining a low-density residential character, meaning houses are generally spaced apart, and developments are controlled to maintain a suburban, family-oriented environment.",
      description1:"",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESIDENTIAL – MIXED HOUSING SUBURBAN ZONE",
      description:"This zone allows for more flexible housing types, including townhouses and apartments, but with an overall focus on a suburban feel. Medium density development is encouraged here, allowing for a mix of single houses, duplexes, and some multi-unit buildings, offering increased housing options without changing the suburban character too much.",
      description1:"",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESIDENTIAL – MIXED HOUSING URBAN ZONE",
      description:"This zone is intended for higher-density housing, allowing for apartments and townhouses in more urban settings. It aims to accommodate a greater number of people by allowing multi-story buildings and smaller lot sizes, while still maintaining a balance with open space and green areas.",
      description1:"",
      src:"",
      title:"PLANNING"
    },    {
      name:"RESIDENTIAL – TERRACE HOUSING AND APARTMENT BUILDINGS ZONE",
      description:"This zone is designed for higher-density housing like apartment buildings and terrace houses (houses that share walls with others). It's intended for urban areas where there’s a need to maximize space efficiency and increase the supply of affordable housing in city centers or near public transport.",
      description1:"",
      src:"",
      title:"PLANNING"
    },    {
      name:"FUTURE URBAN ZONE",
      description:"The Future Urban Zone is land that is planned to become part of the city in the future. Right now, farming and other rural activities are allowed, but urban development (like building homes or businesses) is not allowed until the land is officially changed to an urban zone. It's a way to prepare land for future city growth.",
      description1:"",
      src:"",
      title:"PLANNING"
    },    {
      name:"RURAL ZONES",
      description:"These zones apply to areas outside urban boundaries and are meant for agriculture, forestry, or open space uses. Development is generally more limited to protect the rural character and natural environment. Farmhouses, small villages, and conservation areas are common in rural zones, with fewer houses and larger land areas.",
      description1:"",
      src:"",
      title:"PLANNING"
    },

  ];
  
  export const costingData = [
    {
        name: "CAPITAL GAINS",
        description:"The profit realized when a property is sold for more than its purchase price. It reflects the property's appreciation in value over time and is subject to taxation.",
        description1:'',
        src:'',
        title:'COSTING'
    },
{
  name:"RENTAL YIELD",
  description:"A measure of annual rental income as a percentage of the property's purchase price or market value. It is used to evaluate the income generating potential of a rental property.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"RETURN ON INVESTMENT (ROI)",
  description:"The ratio of net profit to the total investment cost, expressed as a percentage. It measures the financial success of a property development or investment.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:" DEVELOPMENT MARGIN",
  description:"The percentage difference between the total costs of development and the final sale price of the completed property, indicating project profitability.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"BREAK-EVEN POINT",
  description:"The point at which total revenue equals total costs, ensuring no loss or profit. It marks the start of profitability for a project.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:" CONSTRUCTION COSTS",
  description:"The expenses associated with building a property, including materials, labor, permits, and on-site equipment.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"HOLDING COSTS",
  description:"Recurring expenses like property taxes, loan interest, and maintenance incurred while owning a property before its sale or development.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:" SOFT COSTS",
  description:"Non-construction expenses such as architectural fees, legal permits, planning, and consultancy costs integral to the project.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"HARD COSTS",
  description:"Direct construction-related expenses, including materials, labor, equipment, and site preparation.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:" CONTINGENCY BUDGET",
  description:"A reserve fund allocated to cover unforeseen costs or delays during the development process, typically a percentage of the project budget.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"GROSS DEVELOPMENT VALUE (GDV)",
  description:"The estimated market value of a property or project upon completion, used to assess its potential profitability.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:" DEPRECIATION",
  description:"The reduction in a property's value over time due to wear and tear or obsolescence, often used as a tax deduction for investment properties.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"LAND VALUE",
  description:"The market value of the land alone, excluding any buildings or improvements. It serves as the foundation for property development potential.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"EQUITY",
  description:"The difference between the current market value of a property and the amount owed on any loans secured against it. Equity represents the owner's stake in the property.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"ACQUISITION COSTS",
  description:"The total expenses incurred when purchasing a property, including the purchase price, legal fees, stamp duties, and valuation charges.",
  description1:"",
  src:'',
  title:'COSTING'
},
{
  name:"DEVELOPMENT COSTS",
  description:"The sum of all expenses involved in completing a property development project, including land acquisition, design, approvals, construction, and marketing.",
  description1:"",
  src:'',
  title:'COSTING'
} 
  ];
