import { addLayer, addSearchLayer, changeMapView, setCurrentPage, setParcelOverview, setZoneData, toggleLoader, updateCurIds, updateRaw } from "../actions";
import { Urls } from "../utils/AppConfig";
import { fetchPost, generateId } from "../utils/helpers";

export const LoadByAddress = (addressId, dispatch) => {
    dispatch(toggleLoader(true));
    let token = window.localStorage.getItem('token');
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    fetch(`${Urls.ParcelByAddress}?addressId=${addressId}`, { headers: headers })
        .then(r => r.json())
        .then(parcelData => {
            let coors = parcelData.data.addressGeometry.coordinates;
            dispatch(changeMapView([coors[1], coors[0]], 18));
            dispatch(setCurrentPage(1));
            let searchLayerId = `address_search_${generateId(3)}`;
            dispatch(addSearchLayer(searchLayerId, {
                type: 'VECTOR_GJ',
                id: searchLayerId,
                active: true,
                data: parcelData.data.geometry,
                name: 'Address Search',
                sortOrder: 14,
                showLegend: false,
                showInLayerList: false
            }));
            // dispatch(addLayer({
            //     type: 'VECTOR_GJ',
            //     id: searchLayerId,
            //     active: true,
            //     data: parcelData.data.geometry,
            //     name: 'Address Search',
            //     sortOrder: 14,
            //     showLegend: false,
            //     showInLayerList: false
            // }))
            dispatch(updateCurIds({
                parcelId: parcelData.data.properties.parcelId,
                addressId: parcelData.data.properties.address_id,
                titleNo: parcelData.data.properties.titleNo,
                addressGeometry: parcelData.data.addressGeometry,
                fullAddress: parcelData.data.properties['full_address']
            }))
            dispatch(updateRaw({
                address: parcelData.data.raw.address,
                parcel: parcelData.data.raw.parcel,
                gisDetails: parcelData.data.raw.parcelOverview.raw.gisDetails,
                pc78: parcelData.data.raw.parcelOverview.raw.pc78,
                title: parcelData.data.raw.parcelOverview.raw.title,
                salesHistory: parcelData.data.raw.salesHistory,
                rateAssessment: parcelData.data.raw.rateAssessment,
                parcelOverview: parcelData.data.raw.parcelOverview
            }))
            delete parcelData.data.raw.parcelOverview['raw']
            dispatch(setParcelOverview(parcelData.data.raw.parcelOverview));
            dispatch(setZoneData(parcelData.data.raw.zoneRegulations))
            dispatch(toggleLoader(false));


            // fetchPost(Urls.AddSearchHistory, {
            //     parcelId: parcelData.data.properties.parcelId, address: parcelData.data.properties['full_address']
            // }, token)
            //     .then(response => {
            //         if (response.error) {
            //             return;
            //         }
            //         console.log(response);
            //     })

        }).catch(err => {
            console.log(err)
            dispatch(toggleLoader(true));
        })
}