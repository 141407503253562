import React, { useState,useEffect } from 'react';

import { FaFilePdf } from 'react-icons/fa6';
import { Button } from '../../shadcn/components/ui/button';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


const mapStateToProps = (state) => {
  return {
      data: state.DataReducer,
      map: state.MapReducer
  }
}



const InfoSection = (props) => {

  const [clientStatus, setClientStatus] = React.useState(false);



  useEffect(() => {
    window.scrollTo(0, 0);
    if(props.data.user != null){
      setClientStatus(true);
    }  }, [props]);

  const DownloadPdf = (pdfID) => {
     const pdfUrl = "https://drive.usercontent.google.com/u/0/uc?id="+pdfID+"&export=download";
    const link = document.createElement("a");
    link.href = pdfUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <>
        <div className='pb-10   pt-[100px] bg-black text-justify scroll-mt-[72px]'  style={{ fontFamily: 'Verdana, sans-serif' }}>

        <p className="head_font2 text-5xl font-bold text-[#00ff99] pt-5 text-center">
                        Info About Us
                    </p>
      <div className='justify-center items-center flex p-6'>
      <div  className='content-text-about text-xl w-5/6' variant="caption2">
       Welcome to <b><i>Plot Potential</i></b>. We are a pioneering GIS-based land assessment company, 
       committed to providing comprehensive reports and insights to enable individuals and 
       organisations to make informed decisions about their land and property. Our innovative approach combines 
       the power of GIS technology, artificial intelligance and machine learning, along with deep industry expertise to deliver accurate 
       and reliable information.
        </div>     
      </div>

      <div className='flex justify-center items-center '>
<hr className='w-4/5 bg-[#00ff99] h-1'/>
</div>

      <div className='content-text-about text-xl  text-center justify-center items-center flex' variant="caption2">
      <div>
      Check the following documents and videos to know more about us and our products.
      </div>
      </div> 



  <div className='justify-center items-center flex relative'>

  <li className={clientStatus ? 'hidden':'flex items-center absolute justify-center w-full h-full z-10'} >
  <Link to='/signUp' className='bg-[#00ff99] text-black px-8  py-3 '>
  Sign Up To Unlock
  </Link>  
  </li>

<div className='flex flex-col lg:flex-row gap-12 py-12 w-3/4 lg:w-11/12 xl:w-3/4'>

<div className='lg:w-1/2' >
<div className={clientStatus ? '' :'filter blur-[8px]'}>
  <div className=' justify-center flex'>
  <iframe  style={{border:"3px solid #00ff99"}} src="https://drive.google.com/file/d/1JVkonp8DTNji7IUjBvp3NiBpGL62WYTA/preview" className='w-[100%] h-[480px] rounded-t-xl' allow="autoplay"></iframe>
  </div>
  <div style={{cursor:'pointer'}} onClick={() => DownloadPdf('1JVkonp8DTNji7IUjBvp3NiBpGL62WYTA')} className='justify-center flex border-x-3 border-b-3 bg-[#00ff99] border-[#00ff99] rounded-b-xl'>
  <button className=' text-center text-black flex py-1'>
         <FaFilePdf className="self-center" /><span className="px-3">Download</span>
        </button> 
  </div>
  </div>
    </div>




  <div className='lg:w-1/2' >

  <div
  className={clientStatus ? '' :'filter blur-[8px]'}>
  <div className=' justify-center flex'>
  <iframe style={{border:"3px solid #00ff99"}} src="https://drive.google.com/file/d/1cFSq3XAOkP1-2zIPq4tYDoh6Zmp542kE/preview" className='w-[100%] h-[480px] rounded-t-xl' allow="autoplay"></iframe>
  </div>
  <div style={{cursor:'pointer'}} onClick={() => DownloadPdf('1cFSq3XAOkP1-2zIPq4tYDoh6Zmp542kE')} className='justify-center flex border-x-3 bg-[#00ff99] border-b-3 border-[#00ff99] rounded-b-xl'>
  <button className=' text-center text-black flex py-1'>
         <FaFilePdf className="self-center" /><span className="px-3">Download</span>
        </button> 
  </div>
  </div>
  </div>


  {/* <div className='lg:w-1/3' >
  <div className=' justify-center flex'>
  <iframe style={{border:"3px solid #00ff99"}} src="https://drive.google.com/file/d/1az-y2v5lncW4zY_ViuBctjZczyhkmMyz/preview" className='w-[100%] h-[480px] rounded-t-xl' allow="autoplay"></iframe>
  </div>
  <div style={{cursor:'pointer'}} onClick={() => DownloadPdf('1az-y2v5lncW4zY_ViuBctjZczyhkmMyz')} className='justify-center flex border-x-3 border-b-3 bg-[#00ff99] border-[#00ff99] rounded-b-xl'>
  <button className=' text-center text-black flex py-1'>
         <FaFilePdf className="self-center" /><span className="px-3">Download</span>
        </button> 
  </div>
  </div> */}
</div>
</div>


<div className='flex justify-center items-center '>
<hr className='w-4/5 bg-[#00ff99] h-1'/>
</div>


      <div class="grid lg:w-4/5 w-full px-8  lg:space-x-10 py-8 mx-auto lg:gap-8 xl:gap-20 lg:py-16 lg:grid-cols-12">
      <div class=" flex flex-col justify-center items-center place-self-center lg:col-span-5 col-span-12 py-8">
          <div className=''><h2 className="head_font2  font-bold sm:text-4xl text-[#00ff99]">Plot Potential</h2></div>
          <div><h6 className="text-medium sm:text-xl sub_font font-bold text-white ">What Can I do with my Plot ?</h6></div>
        </div>
        <div class="col-span-12 lg:col-span-7  lg:justify-end">
          <iframe width="100%" style={{border:"3px solid #00ff99"}} height="315" className='rounded-xl' src="https://www.youtube.com/embed/oe1cmn_KaZk?si=PwsKzvitBuO4zJFW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div> 
    </div>





    <div class="grid lg:w-4/5 w-full px-8  lg:space-x-10 py-8 mx-auto lg:gap-8 xl:gap-20 lg:py-16 lg:grid-cols-12">
        <div class="col-span-12 lg:col-span-7  lg:justify-end py-8">
        <iframe width="100%" height="315" style={{border:"3px solid #00ff99"}} className='rounded-xl' src="https://www.youtube.com/embed/aDqGNdRtpxY?si=iJ4SSQ9_txjT6AR1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div> 
        <div class=" flex flex-col justify-center items-center place-self-center lg:col-span-5 col-span-12">
          <div className=''><h2 className="head_font2  font-bold sm:text-4xl text-[#00ff99]">Meet Rishabh Arora</h2></div>
          <div><h6 className="text-medium sm:text-xl sub_font font-bold text-white ">Unlock Your Land's Value in 75 Seconds</h6></div>
        </div>
    </div>


<div className='flex justify-center items-center '>
<hr className='w-4/5 bg-[#00ff99] h-1'/>
</div>

    </div>



    </>
    )
    }


export default connect(mapStateToProps)(InfoSection);