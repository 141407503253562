import * as React from "react";
import { Theme } from "../../utils/AppConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HistoryCard = ({ data }) => {
    /***
     * desc: text
     * date: date
     * 
     */
    let dt = new Date(data.properties['instrument_lodged_datetime']);
    return <div style={{ padding: 10, margin: 10 }}>
        <p className="subtitle2" style={{
            textAlign: 'center',
            border: '1px solid ' + Theme.PrimaryLogo,
            color: Theme.PrimaryLogo
        }}>
            {data.properties['instrument_type']}
        </p>
        <Row>
            <Col item xs={6}>
                <p className="caption" style={{ color: '#ddd' }}>
                    {data.properties['memorial_text']}
                </p>
            </Col>

            <Col item xs={6} style={{ textAlign: 'right' }}>
                <p className="caption" style={{ color: '#ddd' }}>
                    <b>{dt.toLocaleDateString()}</b>
                </p>
            </Col>
        </Row>
        <div style={{
            textAlign: 'right', color: '#ddd'
        }}>
            {`${Math.round((new Date() - dt) / (1000 * 60 * 60 * 24 * 365))} years ago`}
        </div>

    </div>
}

const SalesHistory = (props) => {

    let data = props.data;
    if(!data){
        return <></>;
    }

    return <>
        <div id="sales_history" style={{
            margin: '0px 0px 0px 8px',
            padding: '0px 0px 0px 8px'
        }}>
            {
                data.length > 0 ? (
                    <p className='header-1' style={{ color: Theme.PrimaryLogo, }}>
                        History
                    </p>
                ) : ''
            }
        </div>
        {
            data.map(row => {
                return <HistoryCard data={row} key={row.id} />
            })
        }
    </>;
}


export default SalesHistory;