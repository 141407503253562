

import React, { useState,useEffect } from 'react';
import {Accordion, AccordionItem} from "@nextui-org/react";

const ReportSection = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const services =
    [
        {
            id:"1",
            title:"Graphical representations of dwellings",
            content:"Our system harnesses planning, architectural and engineering information to produce detailed graphical representations of potential development scenarios. These include single-storey dwellings, townhouses, duplexes, and zero-lot boundary designs. Each representation highlights architectural designs, floor plans, elevations, and key property features to offer a visual understanding of layout and aesthetics.",
        },
        {
            id:"2",
            title:"Driveway, outlook space and outdoor living space",
            content:"Our reports highlight the potential design and layout of driveways, outlook spaces, and outdoor living areas.They illustrate how these spaces integrate into the overall design of the property, including their size, location, access points, landscaping features, and any amenities such as patios, decks, or gardens.",
          },
        {
            id:"3",
            title:"Red and green flags",
            content:"Plot Potential's system scans properties to identify red and green flags for potential developments.Red flags include site constraints, feasibility restrictions, and Council compliance issues. Identifying these potential issues from the start allows for early resolution and compliance with laws and guidelines.Green flags include affordability, ease of construction, and utility serviceability."
        },
        {
            id:"4",
            title:"Zone perimeters",
            content:"Each report identifies boundaries and perimeters that may affect potential developments.Reports include setbacks, easements, utility corridors, and other physical considerations. They also include legal limitations such as local council regulations that affect the design and construction process.",
          },
        {
            id:"5",
            title:"Stormwater and wastewater engineering",
            content:"Our system assesses the connectivity options for storm- and wastewater to your proposed development.Reports include detailed plans of pipeline connectivity locations and ability to connect these to your property. They even include pipe lengths, invert levels and manhole connections."
        },
        {
            id:"6",
            title:"Project Costing",
            content:"Plot Potential reports estimate your project's total cost and expected ROI. Reports break the cost down into the design stage, consent stage and build stage, to help you assess the project's financial feasibility and cashflow arrangements."
        }
    ];

    return <>
      <section  className='scroll-mt-[72px]' style={{ fontFamily: 'Verdana, sans-serif' }}>
      <div style={{ textAlign: 'center', padding: 0, background: '#111111', paddingTop: 65, paddingBottom: 65 }}>
                <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent', minHeight: '100vh' }}>
                <p className="head_font2 md:text-5xl text-4xl font-bold text-[#00ff99] pt-5 ">
                PLOT POTENTIAL REPORTS
                    </p>
                    <div className='content-text justify-center items-center text-center py-4'>
                    Our reports consolidate data, regulations, site-specific requirements and restraints to showcase the full potential of your property. 
                                </div>



            <div className=' flex items-center justify-center'>
            <div className='md:w-3/4 w-11/12'>


                    <Accordion variant='splitted'
            itemClasses={
                {
                    base:"!bg-[#333333] text-white hover:bg-gradient-to-r from-[#07b16d88] to-[#71aec088]  hover:text-white",
                    title:"text-white"
                }
            }
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: "auto",
            transition: {
              height: {
                type: "spring",
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: "ease",
                duration: 1,
              },
            },
          },
          exit: {
            y: -10,
            opacity: 0,
            height: 0,
            transition: {
              height: {
                easings: "ease",
                duration: 0.25,
              },
              opacity: {
                easings: "ease",
                duration: 0.3,
              },
            },
          },
        },
      }}
    >
            {services.map((item,idx)=>(
                <AccordionItem key={item.id} aria-label={item.id} title={item.title} className='text-left text-xl' >
                {item.content}
                </AccordionItem>
            ))}
            </Accordion>
            </div>
            </div>
                    </div>
                    </div>
                    </section>
</>
}


export default ReportSection;