import React from "react";
import { BackgroundLines } from "./ui/background-lines";
import { SparklesCore } from "./ui/sparkles";

export function ComingSoon() {
  return (
    // (<div
    //   className="h-[30rem] w-full bg-black flex flex-col items-center justify-center overflow-hidden rounded-md">
    //   <h1
    //     className="md:text-7xl text-3xl lg:text-9xl font-bold text-center text-white relative z-20">
    //     Coming Soon
    //   </h1>
    //   <div className="w-[40rem] h-40 relative">
    //     {/* Gradients */}
    //     <div
    //       className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
    //     <div
    //       className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
    //     <div
    //       className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
    //     <div
    //       className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

    //     {/* Core component */}
    //     <SparklesCore
    //       background="transparent"
    //       minSize={0.4}
    //       maxSize={1}
    //       particleDensity={1200}
    //       className="w-full h-full"
    //       particleColor="#FFFFFF" />

    //     {/* Radial Gradient to prevent sharp edges */}
    //     <div
    //       className="absolute inset-0 w-full h-full bg-black [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
    //   </div>
    // </div>)

    (
        <BackgroundLines className="flex items-center justify-center w-full flex-col px-4">
        <h2 className="bg-clip-text text-transparent text-center bg-gradient-to-b from-neutral-600 to-white text-2xl md:text-4xl lg:text-7xl font-sans py-2 md:py-10 relative z-20 font-bold tracking-tight">
          Coming Soon.
        </h2>
        {/* <p className="max-w-xl mx-auto text-sm md:text-lg text-neutral-700 dark:text-neutral-400 text-center">
          Get the best advices from our experts, including expert artists,
          painters, marathon enthusiasts and RDX, totally free.
        </p> */}
      </BackgroundLines>
    )
    
  );
}
