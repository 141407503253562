import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BgVidImg from '../../images/bgvidss.png';

const MainLandingSection = () => {
    return <>
        <header className="relative flex items-center justify-center h-[calc(100vh-70px)]  overflow-hidden">


            <div className='z-10' style={{ textAlign: 'center', padding: 0, position: "absolute", top: 0, width: '100vw', height: '100vh' }}>
                <div elevation={0} className="content-card" style={{ textAnchor: 'middle' }}>
                    <Container>
                        <Row>
                            <Col sm={12} md={4}></Col>
                            <Col xs={12} md={12} style={{ paddingTop: 40, display: 'block', textAlign: 'center' }}>
                                <div style={{ display: 'block' }}>
                                    <br />

<div className='pt-20'>
<h1 className="head_font sm:text-4xl md:text-5xl lg:text-6xl"><strong >Smart design and Build management platform</strong></h1>
<p className="text-xl md:text-2xl pt-3 text-white sub_font font-extralight">Unlock your property’s potential with Plot Potential</p>
<p className="text-2xl md:text-3xl pb-2 text-white sub_font_bold font-extrabold">Starting from $49.</p>
</div>

                                    {/* <Container>
                                        <Row>
                                            <Col xs={12} md={1}></Col>
                                            <Col item xs={12} md={10}><h1 className="head_font text-6xl"><strong >Smart design and Build management platform</strong></h1></Col>
                                            <Col item xs={12} md={1}></Col>
                                        </Row>
                                    </Container> */}

                                    <br />
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={3}></Col>
                                            <Col xs={12} md={6}>
                                                {/* <AddressSearch /> */}
                                                <h1 className="head_font sm:text-4xl md:text-5xl lg:text-6xl"><strong >Coming Soon</strong></h1>
                                            </Col>
                                            <Col xs={12} md={3}></Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>



            <div className=" -z-0 top-0 left-0  object-cover absolute h-screen lg:h-[calc(100vh-72px)] w-screen">
        <img src={BgVidImg} style={{height: "100%", width: "100%" , objectFit: 'cover'}} />
    </div>
            {/* <video
                autoPlay
                loop
                muted
                className="absolute -z-0 w-auto min-w-full min-h-full max-w-none bg-transparent object-cover hidden lg:block"
            >
                <source
                    src={BgVideo}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video> */}
        </header>
    </>
}
export default MainLandingSection;
