import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import LogoImg from '../../images/logo.png';
import LogoImg from '../../images/PPLogo.png';
import NzFlagImg from '../../images/nz_flag.png';
import UserShieldImg from '../../images/user-shield.png';
import { Theme, Urls } from '../../utils/AppConfig';
import { validateToken } from '../../utils/helpers';
import { useDispatch } from "react-redux";
import { setToken, setUser, toggleLoader } from '../../actions';
import { useEffect, useState } from 'react';
import BgVidImg from '../../images/bgvidss.png';
import { Link } from 'react-router-dom';

const SignUpForm2 = () => {

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [clientTag, setClientTag] = useState('');

    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [clientTagError, setClientTagError] = useState(null);

    const checkSignIn = (r) => {
        window.localStorage.setItem('token', r.data);
        dispatch(setToken(r.data));
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    const validatePassword = (value) => {
        return Boolean(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d]).+$/.test(value));
    }

    const validatePhone = (value) => {
        return value.length >= 9 && value.length <= 11;
        // return Boolean(/^0(?:[289]\d{1,2}|[34]\d{2,3})\d{6}$/.test(value))
    }

    const validateFields = () => {
        

        let flag = true;
        if (!Boolean(name) || name.length === 0 || name.length > 100) {
            flag = false;
        }
        if (!Boolean(email) || email.length === 0 || email.length > 100 || !validateEmail(email)) {
            flag = false;
        }
        if (!Boolean(phone) || phone.length === 0 || phone.length > 11 || !validatePhone(phone)) {
            flag = false;
        }
        if (!Boolean(password) || password.length === 0 || password.length > 100 || !validatePassword(password)) {
            flag = false;
        }
        if (!Boolean(cPassword) || cPassword.length === 0 || cPassword.length > 100 || password !== cPassword) {
            flag = false;
        }
        if (!Boolean(clientTag) || clientTag.length === 0) {
            flag = false;
        }
        if (password !== cPassword) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
        name, password, cPassword, email, clientTag, phone
    ])

    const signUpClient = (e) => {
        e.preventDefault();
        console.log("Submitting...")
        dispatch(toggleLoader(true));
        fetch(Urls.ClientSignUp, {
            method: 'POST',
            body: JSON.stringify({
                name, email, password: window.btoa(password), phone, clientTag
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(r => r.json())
            .then(r => {
                console.log(r);
                dispatch(toggleLoader(false));
                if (r.error) {
                    return window.alert(r.message);
                }
                checkSignIn(r)
            }).catch(err => {
                dispatch(toggleLoader(false));
                window.alert("Server error. Please, try again.");
                console.log(err);
            })
    }

    return <>
    <header className="relative flex items-center justify-center  h-screen  lg:h-[calc(100vh-72px)] overflow-hidden" style={{fontFamily: 'Verdana, sans-serif'}}>
   
    <div className=" -z-0 top-0 left-0  object-cover absolute h-screen lg:h-[calc(100vh-72px)] w-screen">
        <img src={BgVidImg} style={{height: "100%", width: "100%" , objectFit: 'cover'}} />
    </div>
   
    <div className='z-10  flex items-center content-card-video h-screen  lg:h-[calc(100vh-72px)]  w-screen '>
            <Container style={{padding:20}}>
                <Row>
                    <Col xs={12} lg={12}>
                        <Row className='flex items-center'>
                            <Col lg={6} style={{ padding: 0 }}>
                                <div className='flex items-center justify-center' >
                                    <img className=' lg:w-5/6 w-3/6' src={LogoImg} />
                                </div>
                            </Col>
                            <Col lg={6} style={{ paddingBottom:0 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='sign-in-user-img-container'>
                                        <img width="75" src={UserShieldImg} />
                                    </div>
                                </div>
                                <div className='sign-in-panel2 '>
                                    <div style={{ padding: '55px 32px 0 32px' }} >
                                        <h2 className='sign-in-header'>Sign Up</h2>
                                    </div>
                                    <div>
                                        <form onSubmit={signUpClient}>
                                            <label className='sign-in-label'>Name<span style={{color:'#ff8686'}}>*</span>&nbsp;</label>
                                            <br />
                                            <input maxLength={100} class="form-control pp-input" style={{marginBottom: 10}} value={name} onChange={(e) => {
                                                let value = e.target.value.replace(/[^A-Za-z]/ig, '')
                                                setName(value);
                                            }} />

                                            <label className='sign-in-label'>Email Id<span style={{color:'#ff8686'}}>*</span>&nbsp;&nbsp;<span style={{color: '#ff8686', fontSize: 14}}>{emailError}</span></label>
                                            <br />
                                            <input maxLength={100} class="form-control pp-input" style={{marginBottom: 10}} value={email} onChange={(e) => {
                                                let value = e.target.value;
                                                if(value.length > 0 && !validateEmail(value)){
                                                    setEmailError("Invalid email")
                                                }else{
                                                    setEmailError("")
                                                }
                                                setEmail(e.target.value)
                                            }} />

                                            <label className='sign-in-label'>Phone<span style={{color:'#ff8686'}}>*</span>&nbsp;&nbsp;<span style={{color: '#ff8686', fontSize: 14}}>{phoneError}</span></label>
                                            <br />
                                            <Row style={{marginBottom: 10}} >
                                                <div style={{ width: 120, display: 'inline-block' }}>
                                                    <input style={{
                                                        background: `url(${NzFlagImg}) no-repeat`,
                                                        backgroundSize: '30px 15px',
                                                        backgroundPosition: 'calc(5px)',
                                                        backgroundColor: 'white',
                                                        paddingLeft: '40px'
                                                    }} class="form-control pp-input" value={'+64'} disabled />
                                                </div>
                                                <div style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                                                    <input maxlength={11} onKeyDown={(event) => {
                                                        if (isNaN(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }} class="form-control pp-input" value={phone} onChange={(e) => {
                                                        let value = e.target.value;
                                                        if(value.length > 0 && !validatePhone(value)){
                                                            setPhoneError("Invalid phone")
                                                        }else{
                                                            setPhoneError("")
                                                        }
                                                        setPhone(e.target.value)
                                                    }} />
                                                </div>
                                            </Row>

                                            <label className='sign-in-label'>Password<span style={{color:'#ff8686'}}>*</span>&nbsp;&nbsp;<span style={{color: '#ff8686', fontSize: 14}}>{passwordError}</span></label>
                                            <br />
                                            <input maxlength={100} class="form-control pp-input" style={{marginBottom: 10}} value={password} onChange={(e) => {
                                                let value = e.target.value;
                                                if(value.length===0){
                                                    setPasswordError("");
                                                }else if(value.length>0 && value.length<8){
                                                    setPasswordError("Minimum 8 characters")
                                                }else if(!validatePassword(value)){
                                                    setPasswordError("Include a letter, a special character, and a number")
                                                }else{
                                                    setPasswordError("")
                                                }
                                                setPassword(e.target.value);
                                            }} type="password" />
                                            <label className='sign-in-label'>Confirm Password<span style={{color:'#ff8686'}}>*</span>&nbsp;&nbsp;<span style={{color: '#ff8686', fontSize: 14}}>{confirmPasswordError}</span></label>
                                            <br />
                                            <input class="form-control pp-input" style={{marginBottom: 10}} name="confirm-password" value={cPassword} onChange={(e) => {
                                                let value = e.target.value;
                                                if(value.length===0){
                                                    setConfirmPasswordError("");
                                                }else if(value !== password){
                                                    setConfirmPasswordError("Password doesn't match")
                                                }else{
                                                    setConfirmPasswordError("")
                                                }
                                                setCPassword(e.target.value);
                                            }} type="password" />
                                            <label className='sign-in-label'>I am a</label>
                                            <br />
                                            <select class="form-control pp-input" name="clientTag" value={clientTag} onChange={(e) => setClientTag(e.target.value)}>
                                            <option value="">Select</option>
                                            <option value="Real Estate Agent">Real Estate Agent</option>
                                            <option value="Real Estate Investor">Real Estate Investor</option>
                                            <option value="Property Developer">Property Developer</option>
                                            <option value="Builder">Builder</option>
                                            <option value="Consultant">Consultant</option>
                                            <option value="General Property Buyer">General Property Buyer</option>
                                            </select>
                                            <br />
                                            <div style={{ textAlign: 'center' }} className='pb-3'>
                                                <input disabled={!formValid} class="btn" type="submit" onClick={signUpClient} />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='flex items-center justify-center pt-2 pb-3'>
                                    <div className='text-white text-sm'>Already have an Account ? <Link style={{ color: Theme.PrimaryLogo }} to={'/signIn'}><span className='text-[#00ff99] text-lg'>SignIn</span></Link></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            </div>

    </header>
</>

}

export default SignUpForm2;