"use client";
import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClick } from "./hooks/use-outside-click";

import CostingLogo from '../../images/pdf/costing.png'
import PlanningLogo from '../../images/pdf/planning.png'
import ArchitectureLogo from '../../images/pdf/architecture.png'
import EngineeringLogo from '../../images/pdf/engineering.png'

import ArchitectureMain from '../../images/pdf/Architecture.jpg'
import PlanningMain from '../../images/pdf/Planning.jpg'
import EngineeringMain from '../../images/pdf/Engineering.jpg'
import CostingMain from '../../images/pdf/costing.jpg'


export function ExpandableCard({sendDataToParent}) {
  const [active, setActive] = useState(null);
  const id = useId();
  const ref = useRef(null);


  const sendData = () => {
    setActive(null);
    const data = active.ctaLink;
     sendDataToParent(data);
  };

  useEffect(() => {
    function onKeyDown(event) {
      if (event.key === "Escape") {
        setActive(false);
      }
    }

    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [active]);

  useOutsideClick(ref, () => setActive(null));

  return (<>
    <AnimatePresence>
      {active && typeof active === "object" && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/20 h-full w-full z-10" />
      )}
    </AnimatePresence>
    <AnimatePresence>
      {active && typeof active === "object" ? (
        <div className="fixed inset-0  grid place-items-center z-[100]">
          <motion.button
            key={`button-${active.title}-${id}`}
            layout
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.05,
              },
            }}
            className="flex absolute top-2 right-2 lg:hidden items-center justify-center bg-white rounded-full h-6 w-6"
            onClick={() => setActive(null)}>
            <CloseIcon />
          </motion.button>
          <motion.div
            layoutId={`card-${active.title}-${id}`}
            ref={ref}
            className="w-full max-w-[500px]  h-full md:h-fit md:max-h-[90%]  flex flex-col bg-white  sm:rounded-3xl overflow-hidden">
            <motion.div layoutId={`image-${active.title}-${id}`}>
              <img
                priority
                width={200}
                height={200}
                src={active.mainsrc}
                alt={active.title}
                className="w-full h-80 lg:h-80 sm:rounded-tr-lg sm:rounded-tl-lg object-cover object-top" />
            </motion.div>

            <div>
              <div className="flex justify-between items-start p-4">
                <div className="pt-3">
                  <motion.h3
                    layoutId={`title-${active.title}-${id}`}
                    className="font-extrabold text-xl text-black ">
                    {active.title}
                  </motion.h3>
                  {/* <motion.p
                    layoutId={`description-${active.description}-${id}`}
                    className="text-neutral-600  text-base">
                    {active.description}
                  </motion.p> */}
                </div>

                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  // href={active.ctaLink}
                  // target="_blank"
                  onClick={sendData}
                  className="px-4 py-3 text-sm rounded-full font-bold bg-green-500 text-white cursor-pointer">
                  {active.ctaText}
                </motion.div>
              </div>
              <div className="pt-4 relative px-4">
                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="text-neutral-600 text-xs md:text-sm lg:text-base h-40 md:h-fit pb-10 flex flex-col items-start gap-4 overflow-auto  [mask:linear-gradient(to_bottom,white,white,transparent)] [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-overflow-scrolling:touch]">
                  {typeof active.content === "function"
                    ? active.content()
                    : active.content}
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
    <ul
      className=" mx-auto w-3/4 grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-start gap-4">
      {cards.map((card, index) => (
        <motion.div
          layoutId={`card-${card.title}-${id}`}
          key={card.title}
          onClick={() => setActive(card)}
          className="p-4 flex flex-col  group hover:bg-[#00ff99]  rounded-xl cursor-pointer">
          <div className="flex gap-4 flex-col  w-full">
            <motion.div layoutId={`image-${card.title}-${id}`}>
              <img
                width={100}
                height={100}
                src={card.src}
                alt={card.title}
                className="h-60 w-full   object-contain object-top" />
            </motion.div>
            <div className="flex justify-center items-center flex-col">
              <motion.h3
                layoutId={`title-${card.title}-${id}`}
                className=" text-[#00ff99] group-hover:text-black text-xl font-bold text-center md:text-left">
                {card.title}
              </motion.h3>
              {/* <motion.p
                layoutId={`description-${card.description}-${id}`}
                className="text-neutral-600  text-center md:text-left text-base">
                {card.description}
              </motion.p> */}
            </div>
          </div>
        </motion.div>
      ))}
    </ul>
  </>);
}

export const CloseIcon = () => {
  return (
    (<motion.svg
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.05,
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4 text-black">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 6l-12 12" />
      <path d="M6 6l12 12" />
    </motion.svg>)
  );
};

const cards = [
  {
    description: "Lana Del Rey",
    title: "Engineering",
    src:EngineeringLogo,
    mainsrc:EngineeringMain,
    ctaText: "View Definitions",
    ctaLink: "1",
    content: () => {
      return (
        (<p>Engineering covers the technical and structural aspects
            necessary for safe and compliant property development.
            From site preparation to foundational integrity, these terms
            provide insight into the requirements for robust and
            sustainable construction.
                  </p>)
      );
    },
  },
  {
    description: "Babbu Maan",
    title: "Architecture",
    src: ArchitectureLogo,
    mainsrc:ArchitectureMain,
    ctaText: "View Definitions",
    ctaLink: "2",
    content: () => {
      return (
        (<p>Architecture focuses on design, building layout, and spatial
            arrangements. This section encompasses key concepts that
            guide aesthetic and functional aspects of property
            development.
                  </p>)
      );
    },
  },

  {
    description: "Metallica",
    title: "Planning",
    src: PlanningLogo,
    mainsrc:PlanningMain,
    ctaText: "View Definitions",
    ctaLink: "3",
    content: () => {
      return (
        (<p> Planning encompasses land-use regulations, zoning, and
            necessary permits to ensure compliance with environmental
            and community standards.
                  </p>)
      );
    },
  },
  {
    description: "Lord Himesh",
    title: "Costing",
    src: CostingLogo,
    mainsrc:CostingMain,
    ctaText: "View Definitions",
    ctaLink: "4",
    content: () => {
      return (
        (<p> Definitions that relate directly to budget considerations, cost
 planning, and financial compliance in property development.
                  </p>)
      );
    },
  },
];
