export const toggleLayerState = (layerId, active) => {
    return {
        type: 'TOGGLE_LAYER_STATE',
        payload: { layerId, active }
    }
};
export const changeMapZoom = (zoom) => {
    return {
        type: 'CHANGE_ZOOM',
        payload: zoom
    }
}
export const changeMapView = (center, zoom) => {
    return {
        type: 'CHANGE_MAP_VIEW',
        payload: { center, zoom }
    }
}
export const mapViewWasSet = (center, zoom) => {
    return {
        type: 'MAP_VIEW_WAS_SET',
        payload: { center, zoom }
    }
}
export const addLayer = (layer) => {
    return {
        type: 'ADD_LAYER',
        payload: layer
    }
};
export const addSearchLayer = (layerId, data) => {
    return {
        type: 'ADD_SEARCH_LAYER',
        payload: { layerId, data }
    }
};
export const updateCurIds = (data) => {
    return {
        type: 'UPDATE_CUR_IDS',
        payload: data
    }
};
export const removeSearchLayer = () => {
    return {
        type: 'REMOVE_SEARCH_LAYER',
        payload: []
    }
};
export const setCurrentPage = (pageId) => {
    return {
        type: "SET_CUR_PAGE",
        payload: pageId
    }
};

export const toggleLoader = (flag, message = "Please wait...") => {
    return {
        type: "TOGGLE_LOADER",
        payload: { flag, message }
    }
};

export const toggleReportLoader = (flag, message = "Please wait...") => {
    return {
        type: "TOGGLE_REPORT_LOADER",
        payload: { flag, message }
    }
};

export const toggleProgressLoader = (flag, message = "Please wait...", percentage = 0) => {
    return {
        type: "TOGGLE_PROGRESS_LOADER",
        payload: { flag, message, percentage }
    }
};

export const updateRaw = (data) => {
    return {
        type: "UPDATE_RAW",
        payload: data
    }
};

export const toggleBaseMap = (map) => {
    return {
        type: "TOGGLE_BASEMAP",
        payload: map
    }
}

export const toggleAlertDialog = (flag, title, message) => {
    return {
        type: "TOGGLE_ALERT_DIALOG",
        payload: { flag, title, message }
    }
}

export const toggleManualOrderDialog = (flag, parcel, preprocess) => {
    if (parcel === undefined) {
        parcel = null;
    }
    return {
        type: "TOGGLE_MANUAL_ORDER_DIALOG",
        payload: { flag, parcel, preprocess }
    }
}

export const toggleSignInDialog = (flag) => {
    return {
        type: "TOGGLE_SIGN_IN_DIALOG",
        payload: { flag }
    }
}

export const toggleSignUpDialog = (flag) => {
    return {
        type: "TOGGLE_SIGN_UP_DIALOG",
        payload: { flag }
    }
}

export const setUser = (user) => {
    return {
        type: "SET_USER",
        payload: user
    }
}
export const toggleSamplePlansDialog = (flag, imgs, quota) => {
    return {
        type: "TOGGLE_SAMPLE_PLANS_DIALOG",
        payload: { flag, imgs, quota }
    }
}
export const setPayments = (payments) => {
    return {
        type: "SET_PAYMENTS",
        payload: payments
    }
}
export const setCurrentSubscription = (subscriptionData) => {
    return {
        type: "SET_CUR_SUBSCRIPTION",
        payload: subscriptionData
    }
}
export const setReturnUrl = (returnUrl) => {
    return {
        type: "SET_RETURN_URL",
        payload: returnUrl
    }
}
export const setParcelOverview = (parcelOverview) => {
    return {
        type: "SET_PARCEL_OVERVIEW",
        payload: parcelOverview
    }
}
export const setZoneData = (zoneData) => {
    return {
        type: "SET_ZONE_DATA",
        payload: zoneData
    }
}
export const setToken = (token) => {
    return {
        type: "SET_TOKEN",
        payload: token
    }
}