// lib/utils.ts
import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { Urls } from "../utils/AppConfig";
import { fetchPost } from "../utils/helpers";
import { useEffect, useState } from "react"

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const ColorMap = {
  3: "success",
  2: "danger",
  4: "warning",
  1:"primary",
  0:"default"
};

export const statusMap = {
  3: "Approved",
  2: "Rejected",
  4: "Corrections",
  1:"In-Progress",
  0:"In-Active"
};


export const crmAppStep =  {
  
2:"2"
  // 

  // useEffect(() => {
  //   crmAppStep();


    
  // }, []);

  //   let url = Urls.GetCRMAppStep;
  //   let token = window.localStorage.getItem('token');
  //   fetchPost(url, {}, token)
  //     .then(response => {
  //       console.log(response);
  //       if (response.error) {
  //         console.log("Error");
  //         setCrmAppStepData([]);
  //       } else {
  //         setCrmAppStepData(response.data)

  //       }
  //     })
  //     .catch(err => console.log(err));
};








