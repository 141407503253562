import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RepScShImg from '../../images/i3.jpg';
import LogoImg from '../../images/logo.js';
import PPLOGO from "../../images/PPLogo.png"
import React, { useState,useEffect } from 'react';

const AboutSection = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

    return <>
        <section id='about' >
            <div style={{ textAlign: 'center', padding: 0, background: '#111111', paddingTop: 65, paddingBottom: 65 }}>
                <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent', minHeight: '100vh' }}>
                    <p className="head_font2 text-5xl font-bold text-[#00ff99] pt-5 ">
                        ABOUT US
                    </p>
                    <Container>
                        <Row style={{ padding: 16 }}>
                            <Col xs={12} md={6} style={{ textAlign: 'center', display: 'flex' }}>
                            <img src={PPLOGO} style={{ width: '100%', maxWidth: 600, margin: 'auto' }} />
                                {/* <LogoImg style={{ width: '100%', maxWidth: 600, margin: 'auto' }} /> */}
                            </Col>
                            <Col xs={12} md={6} style={{ marginTop: 16 }}>
                                <div >
                                <p variant="caption2" className="content-text-about text-xl">
                                After years of experience in Auckland's property industry, Rishabh, Plot Potential's CEO, recognised just how difficult it was to get clear answers about a property's development potential.
                                </p>


<h2 class="mb-2 text-start text-xl font-semibold  text-[#999999]">Questions like:</h2>

<ul class=" text-lg text-start float-start space-y-1 text-[#999999] list-disc list-outside ">
    <li className='float-start'>
    How many townhouses can I build?
    </li>
    <li className='float-start'>
    Can I add a minor dwelling to my property?         
    </li>
    <li className=' float-start'>
    Are there zoning or regulatory issues with the land I want to buy?
    </li>
    <li className=' float-start'>
    What will my development ideas cost?
    </li>
</ul>
                                {/* Welcome to <b><i>Plot Potential</i></b>. We are a pioneering GIS-based land assessment company, committed to providing comprehensive reports and insights to enable individuals and organisations to make informed decisions about their land and property. Our innovative approach combines the power of GIS technology, artificial intelligance and machine learning, along with deep industry expertise to deliver accurate and reliable information. */}
                                <br />
                              
                                <p variant="caption2" className="content-text-about text-xl">
                                Rishabh saw the frustration firsthand: hours spent speaking with architects, planners, and engineers, waiting months for feasibility reports, and paying thousands for expert advice—only to still be left with uncertainty.
                                </p>
                                <br />
                                    <br />
                                    <p className="content-text-2" style={{ textAlign: 'center' }}>
                                    That’s why Plot Potential was created.
                                </p>

                                {/* At <b><i>Plot Potential</i></b>, we understand that owning land or property comes with a myriad of questions and uncertainties. Our mission is to empower our clients by answering the fundamental question: "What can I do with my plot?" We believe that every plot of land has untapped potential, and our goal is to unlock its possibilities. We help our customers navigate the complexities of zoning requirements, development engineering constraints and environmental considerations. */}
                                {/* Our platform offers comprehensive, easy-to-understand reports that empower you to make confident, informed decisions about your property. No more waiting for multiple experts or shelling out for every answer. */}
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <br />
                        <Row style={{ padding: 16 }}>
                            <Col sm={12} md={6}>
                                <p variant="caption2" className="content-text-about text-xl" >
                                Our platform offers comprehensive, easy-to-understand reports that empower you to make confident, informed decisions about your property. No more waiting for multiple experts or shelling out for every answer.
                                <br />

                                    {/* At <b><i>Plot Potential</i></b>, we are driven by a vision to help the community unlock the potential of their land. We believe that by offering comprehensive and user-friendly land assessment reports, we can empower individuals, developers, and organizations to realize their dreams, whether it's building their dream home, launching a commercial venture, or contributing to sustainable urban development. */}
                               
                                    {/* We are excited to be on this journey with you and look forward to partnering with you in uncovering the hidden potential of your land. Join us in shaping a future where every plot of land is seen as an opportunity waiting to be realized.
                                    <br />
                                    <br /> */}
                                </p>


                                <p className="content-text-2" style={{ textAlign: 'center' }}>
                                With Plot Potential, you get clear answers in <b><i>just 75 seconds</i></b>.
                                </p>
                                <p variant="caption2" className="content-text-about text-xl" >
                                Our team of AI and machine learning specialists, GIS experts, and software developers has spent years perfecting a tool that unlocks the full potential of your property. We’re here to help you turn your development ideas into reality—quickly and efficiently.
                                </p>

                                            <br/>
                                            <br/>

                            </Col>
                            <Col sm={12} md={6}>
                                <img src={RepScShImg} style={{ maxWidth: '100%' }} />
                            </Col>
                        </Row>

                    </Container>
                </div>
                <br />
                {/* <IconButton style={{ color: 'white', border: '1px solid white' }}>
                    <KeyboardDoubleArrowDownIcon />
                </IconButton> */}
            </div>

            <div style={{}}>
                <div style={{ textAlign: 'center', padding: 0, background: '#171717', paddingTop: 65, paddingBottom: 65 }}>
                    <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent' }}>

                        <Container>
                            <Row style={{ padding: 16 }}>
                                <Col sm={12} md={4}>
                                </Col>
                                <Col sm={12} md={8} style={{ padding: 32 }}>
                                    <p className="content-text-about text-xl">

                                    Explore our site to learn more, or reach out to book a free demo, either online or in-person. We're here to show you the power of Plot Potential.
                                    <br/>
                                    <br/>

                                    Auckland's property market is full of untapped potential. Let us help you unlock it.
                                    </p>
                                    <br />
                                    <p className="content-text-2-about text-xl text-justify" >
                                        As our Founder says, “Future efficiencies will come from proactively transforming how we do business in today’s time." - Rishabh Arora (CEO and Founder)
                                    </p>
                                </Col>
                            </Row>
                        </Container>


                    </div>
                    <br />
                </div>
            </div>


        </section>
    </>
}

export default AboutSection;