
import { Link } from "react-router-dom";
import { Theme } from '../utils/AppConfig';
import { useState,useEffect } from "react";
import { GeneralPopUp } from "./GeneralPopup";
import {Input} from "@nextui-org/react";
import { EyeSlashFilledIcon,EyeFilledIcon,MailIcon } from "../icons/GeneralIcons";

import { EyeNoneIcon,EyeOpenIcon } from "@radix-ui/react-icons";


export const ResetPassword = () => {  
    const [email, setEmail] = useState('');
    const [formValid, setFormValid] = useState(false);
     const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

    const validateFields = () => {
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        let flag = true;
        if (!Boolean(email) || email.length === 0 || email.length > 100 || !validateEmail(email)) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
         email
    ])

  const sendResetEmail = () =>{

                  let  data = {
              status:false,
              msg:'Something Went Wrong',
              url:'/'
          };
          let  data2 = {
            status:true,
            msg:'Email Sent Successfully !',
            url:'/'
        };

        // fetchPost(Urls.RegisterLaunch)
        // .then(response => {
        //   console.log(response);
        //   let data = {};
        //   if (response.error) {
        //      data = {
        //       status:false,
        //       msg:'Something Went Wrong'
        //   };
        //   }else{
        //     data = {
        //       status:true,
        //       msg:response.message,
        //       data:response.data
        //   };
        //   setStepThree(true);
        //   handleConfetti();
        //   }
        // setData(data);
        // });
        setData(data);
        setStatus(true);



  }


    return(
        <>
            {status ?<GeneralPopUp props={data}/>:''}

<section class="bg-black rounded-3xl py-4">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
<div className="p-3">
<svg fill="#00ff99"  viewBox="0 0 52 52" width={70} height={70} enable-background="new 0 0 52 52"  stroke="#00ff99"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M42,23H10c-2.2,0-4,1.8-4,4v19c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V27C46,24.8,44.2,23,42,23z M31,44.5 c-1.5,1-3.2,1.5-5,1.5c-0.6,0-1.2-0.1-1.8-0.2c-2.4-0.5-4.4-1.8-5.7-3.8l3.3-2.2c0.7,1.1,1.9,1.9,3.2,2.1c1.3,0.3,2.6,0,3.8-0.8 c2.3-1.5,2.9-4.7,1.4-6.9c-0.7-1.1-1.9-1.9-3.2-2.1c-1.3-0.3-2.6,0-3.8,0.8c-0.3,0.2-0.5,0.4-0.7,0.6L26,37h-9v-9l2.6,2.6 c0.4-0.4,0.9-0.8,1.3-1.1c2-1.3,4.4-1.8,6.8-1.4c2.4,0.5,4.4,1.8,5.7,3.8C36.2,36.1,35.1,41.7,31,44.5z"></path> 
<path d="M10,18.1v0.4C10,18.4,10,18.3,10,18.1C10,18.1,10,18.1,10,18.1z"></path>
 <path d="M11,19h4c0.6,0,1-0.3,1-0.9V18c0-5.7,4.9-10.4,10.7-10C32,8.4,36,13,36,18.4v-0.3c0,0.6,0.4,0.9,1,0.9h4 c0.6,0,1-0.3,1-0.9V18c0-9.1-7.6-16.4-16.8-16c-8.5,0.4-15,7.6-15.2,16.1C10.1,18.6,10.5,19,11,19z"></path> </g> </g>
 </svg>
</div>
      <div class="w-full p-6 bg-[#333333] rounded-lg shadow  md:mt-0 sm:max-w-md   sm:p-8">
          <h1 class="mb-1 text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
              Forgot your password?
          </h1>
          <p class="font-light text-gray-300 ">Don't fret! Just type in your email and we will send you a link to reset your password!</p>
          <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
              <div>
                  <label for="email" class="block mb-2 text-sm font-medium text-white">Email</label>

                  <Input
          type="email"
          placeholder="Enter Your Email"
          id="email"
          name="email"
          isRequired
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          className=" text-gray-900 text-sm block w-full"
          endContent={
            <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" width={20} height={20} />
          }
          classNames={{
            inputWrapper:["bg-white"],
            input:["text-black"]
          }}
        />
              </div>

              <button disabled={!formValid} type="button"  className={`${formValid ?'cursor-pointer bg-[#00ff99]' : 'cursor-not-allowed bg-[#00ff99]/50'} w-full text-black  font-medium rounded-lg text-sm px-5 py-2.5 text-center`}   onClick={sendResetEmail} >Send Email</button>
          </form>
      </div>
  </div>
</section>  
</>


    )
}





export const NewPassword = () => {  
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [formValid, setFormValid] = useState(false);
     const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);
    const toggleVisibility2 = () => setIsVisible2(!isVisible2);

    const validateFields = () => {

        let flag = true;
        if (!Boolean(password) || password.length === 0 || password.length > 100) {
            flag = false;
        }
        if (!Boolean(cPassword) || cPassword.length === 0 || cPassword.length > 100) {
            flag = false;
        }
        if (password !== cPassword) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
        password, cPassword
    ])


  const resetPassword = () =>{

                  let  data = {
              status:false,
              msg:'Something Went Wrong',
              url:'/'
          };
          let  data2 = {
            status:true,
            msg:'Password Changed Successfully !',
            url:'/'
        };

        // fetchPost(Urls.RegisterLaunch)
        // .then(response => {
        //   console.log(response);
        //   let data = {};
        //   if (response.error) {
        //      data = {
        //       status:false,
        //       msg:'Something Went Wrong'
        //   };
        //   }else{
        //     data = {
        //       status:true,
        //       msg:response.message,
        //       data:response.data
        //   };
        //   setStepThree(true);
        //   handleConfetti();
        //   }
        // setData(data);
        // });
        setData(data2);
        setStatus(true);



  }


    return(
        <>
            {status ?<GeneralPopUp props={data}/>:''}

            <section class="bg-black rounded-3xl py-4">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
<div className="p-3">
<svg fill="#00ff99"  viewBox="0 0 52 52" width={70} height={70} enable-background="new 0 0 52 52"  stroke="#00ff99"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M42,23H10c-2.2,0-4,1.8-4,4v19c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V27C46,24.8,44.2,23,42,23z M31,44.5 c-1.5,1-3.2,1.5-5,1.5c-0.6,0-1.2-0.1-1.8-0.2c-2.4-0.5-4.4-1.8-5.7-3.8l3.3-2.2c0.7,1.1,1.9,1.9,3.2,2.1c1.3,0.3,2.6,0,3.8-0.8 c2.3-1.5,2.9-4.7,1.4-6.9c-0.7-1.1-1.9-1.9-3.2-2.1c-1.3-0.3-2.6,0-3.8,0.8c-0.3,0.2-0.5,0.4-0.7,0.6L26,37h-9v-9l2.6,2.6 c0.4-0.4,0.9-0.8,1.3-1.1c2-1.3,4.4-1.8,6.8-1.4c2.4,0.5,4.4,1.8,5.7,3.8C36.2,36.1,35.1,41.7,31,44.5z"></path> 
<path d="M10,18.1v0.4C10,18.4,10,18.3,10,18.1C10,18.1,10,18.1,10,18.1z"></path>
 <path d="M11,19h4c0.6,0,1-0.3,1-0.9V18c0-5.7,4.9-10.4,10.7-10C32,8.4,36,13,36,18.4v-0.3c0,0.6,0.4,0.9,1,0.9h4 c0.6,0,1-0.3,1-0.9V18c0-9.1-7.6-16.4-16.8-16c-8.5,0.4-15,7.6-15.2,16.1C10.1,18.6,10.5,19,11,19z"></path> </g> </g>
 </svg>
</div>



      <div class="w-full p-6 bg-[#333333] rounded-lg shadow dark:border md:mt-0 sm:max-w-md  sm:p-8">
          <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-white md:text-2xl ">
              Change Password
          </h2>
          <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" >
              
              
          <div className="w-full">
          <label for="password" class="block mb-2 text-sm font-medium text-white">New Password</label>
              <Input
              variant="flat"
      placeholder="Your New password"
      radius="sm"
      className=" text-gray-900 text-sm block w-full"
      id="password"
      name="password" 
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      endContent={
        <button className="focus:outline-none" type="button" onClick={toggleVisibility} aria-label="toggle password visibility">
          {isVisible ? (
             <EyeFilledIcon className="text-3xl text-default-400 pointer-events-none" width={20} height={20}/>
          ) : (
            <EyeSlashFilledIcon className="text-3xl text-default-400 pointer-events-none" width={20} height={20} />
          )}
        </button>
      }
      type={isVisible ? "text" : "password"}
      classNames={{
        inputWrapper:["bg-white"],
        input:["text-black"]
      }}
    />
              </div>


              <div className="w-full">
              <label for="confirm-password" class="block mb-2 text-sm font-medium text-white">Confirm password</label>
            <Input
            variant="flat"
            radius="sm"
            placeholder="Re-Type Password"
            className=" text-gray-900 text-sm block w-full "
            id="confirm-password"
            name="confirm-password" 
            value={cPassword}
            onChange={(e) => setCPassword(e.target.value)}
            endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility2} aria-label="toggle password visibility">
                {isVisible2 ? (
                    <EyeFilledIcon className="text-3xl text-default-400 pointer-events-none" width={20} height={20}/>
                ) : (
                    <EyeSlashFilledIcon className="text-3xl text-default-400 pointer-events-none" width={20} height={20} />
                )}
                </button>
            }
            type={isVisible2 ? "text" : "password"}
            classNames={{
                inputWrapper:["bg-white"],
                input:["text-black"]
              }}
            />
              </div>
              
              <button disabled={!formValid} type="button"  className={`${formValid ?'cursor-pointer bg-[#00ff99]' : 'cursor-not-allowed bg-[#00ff99]/50'} w-full text-black  font-medium rounded-lg text-sm px-5 py-2.5 text-center`}   onClick={resetPassword} >Reset password</button>
          </form>
      </div>


  </div>
</section>
</>


    )
}
