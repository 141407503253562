"use client";
import { View } from "@react-pdf/renderer";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

export const AnimatedTestimonials = ({
  testimonials,
  viewDefinition,
  buttonStatus,
  autoplay = false
}) => {


  const [active, setActive] = useState(0);
  const [view, setView] = useState(false);


  const handleNext = () => {
    setActive((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setActive((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  const isActive = (index) => {
    return index === active;
  };

  useEffect(() => {
  //  alert(viewDefinition);
    setView(viewDefinition);
    if (autoplay) {
      const interval = setInterval(handleNext, 5000);
      return () => clearInterval(interval);

    }
  }, [autoplay,viewDefinition,buttonStatus]);

  const randomRotateY = () => {
    return Math.floor(Math.random() * 21) - 10;
  };
  return (
    (
    <div className={`${view ? '' : 'hidden'}`}>
        <div className='flex justify-center items-center '>
    <hr className='w-4/5 bg-[#00ff99] h-1'/>
    </div>
    <div
      className="mx-auto  antialiased font-sans px-4 md:px-8 lg:px-12 py-20">
      <div className="relative grid grid-cols-1 md:grid-cols-12 gap-10">
        <div className="col-span-4 w-full">
          <div className="relative h-80">
            <AnimatePresence>
              {testimonials.map((testimonial, index) => (
                <motion.div
                  key={testimonial.src}
                  initial={{
                    opacity: 0,
                    scale: 0.9,
                    z: -100,
                    rotate: randomRotateY(),
                  }}
                  animate={{
                    opacity: isActive(index) ? 1 : 0,
                    scale: isActive(index) ? 1 : 0.95,
                    z: isActive(index) ? 0 : -100,
                    rotate: isActive(index) ? 0 : randomRotateY(),
                    zIndex: isActive(index)
                      ? 999
                      : testimonials.length + 2 - index,
                    y: isActive(index) ? [0, -80, 0] : 0,
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0.9,
                    z: 100,
                    rotate: randomRotateY(),
                  }}
                  transition={{
                    duration: 0.4,
                    ease: "easeInOut",
                  }}
                  className="absolute inset-0 origin-bottom ">
                  <img
                    src={testimonial.src}
                    alt={testimonial.name}
                    width={500}
                    height={500}
                    draggable={false}
                    className="h-full  w-full rounded-3xl object-contain object-center" />
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>

        <div className="col-span-8">
        <div className="flex justify-between flex-col py-4">
          <motion.div
            key={active}
            initial={{
              y: 20,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: -20,
              opacity: 0,
            }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
            }}>
            <h3 className="text-2xl font-bold text-[#00ff99]">
              {testimonials[active].name}
            </h3>
            <p className="text-lg font-bold text-[#00ff99] ">
              {testimonials[active].title}
            </p>
            <motion.p className="text-lg text-white">
              {testimonials[active].description.split(" ").map((word, index) => (
                <motion.span
                  key={index}
                  initial={{
                    filter: "blur(10px)",
                    opacity: 0,
                    y: 5,
                  }}
                  animate={{
                    filter: "blur(0px)",
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    duration: 0.2,
                    ease: "easeInOut",
                    delay: 0.02 * index,
                  }}
                  className="inline-block">
                  {word}&nbsp;
                </motion.span>
              ))}
            </motion.p>





            <motion.p className="text-lg text-white mt-8 ">
              {testimonials[active].description1.split(" ").map((word, index) => (
                <motion.span
                  key={index}
                  initial={{
                    filter: "blur(10px)",
                    opacity: 0,
                    y: 5,
                  }}
                  animate={{
                    filter: "blur(0px)",
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    duration: 0.2,
                    ease: "easeInOut",
                    delay: 0.02 * index,
                  }}
                  className="inline-block">
                  {word}&nbsp;
                </motion.span>
              ))}
            </motion.p>




          </motion.div>
          <div className="flex gap-4 pt-12 md:pt-0">
            <button
              onClick={handlePrev}
              className="h-7 w-7 rounded-full bg-[#00ff99] flex items-center justify-center group/button">
              <IconArrowLeft
                className="h-5 w-5 text-black  group-hover/button:rotate-12 transition-transform duration-300" />
            </button>
            <button
              onClick={handleNext}
              className="h-7 w-7 rounded-full bg-[#00ff99]  flex items-center justify-center group/button">
              <IconArrowRight
                className="h-5 w-5 text-black  group-hover/button:-rotate-12 transition-transform duration-300" />
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
)



  );
};
