import HomeHeader from "../components/HomeHeader";
import QuotationTable from "../components/Profile/plotworks/quotations/page";

const QuotationsPage = (props) => {
    return <>
        <HomeHeader />
        <div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <h3 className="text-[#00ff99] p-3">PlotWorks Quotations</h3>
    <QuotationTable />
</div>
</div>
    </>
}

export default QuotationsPage;