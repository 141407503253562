import { connect, useDispatch } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import LoaderGif from '../images/loader.gif';
import { toggleProgressLoader } from '../actions';

const mapStateToProps = state => {
    return {
        map: state.MapReducer
    }
};

const ProgressLoader = (props) => {
    const dispatch = useDispatch();
    return <>
        <div style={{
            display: props.map.showProgressLoader ? 'block' : 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            textAlign: 'center',
            paddingTop: 100,
            backgroundColor: '#000000BB',
            zIndex: 2000
        }}>
            <div style={{
                marginTop: 50,
                marginLeft: 'calc(50% - 150px)',
                width: '300px',
                background: 'white',
                textAlign: 'center',
                padding: 20,
                paddingTop: 32,
                borderRadius: 10,
                background: 'rgba(60, 60, 60, 0.4)',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(5px)',
                webkitBackdropFilter: 'blur(5px)',
                color: '#00FF99'
            }}>
                {/* <div style={{ display: 'inline-block' }}><img width={50} height={50} src={LoaderGif} /></div> */}
                <ProgressBar animated variant='success' now={props.map.loaderProgressPercent} label={`${props.map.loaderProgressPercent}%`} />
                <br/>
                <div>{props.map.loaderProgressMessage}</div>
                {/* <button onClick={()=>{
                    dispatch(toggleProgressLoader(false));
                }}>cancel</button> */}
            </div>
        </div>
    </>
}

export default connect(mapStateToProps)(ProgressLoader);