
import * as React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const OverviewListItem = ({ data }) => {
    return <Row container>
        <Col item xs={6}>
            <p className="subtitle2">{data.key}</p>
        </Col>
        <Col item xs={6}>
            <p className={'caption'}>{data.value}</p>
        </Col>
    </Row>
}

const OverviewDetails = (props) => {


    if (!props.data) {
        return '';
    }
    let data = props.data;

    return <div style={{
        padding: 15,
        color: '#fff'
    }}>
        <p style={{ textAlign: 'center' }} >
            Overview
        </p>
        <div>
            {
                Object.keys(data).map(k => {
                    return <OverviewListItem key={k} data={{ key: k, value: data[k] }} />
                })
            }
        </div>
    </div>
}

export default OverviewDetails;